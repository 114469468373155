<div class="mil-modal">
  <div class="tabs">
    <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Veure signants</div>
    <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Veure document</div>
  </div>

  <div class="modal-box-split">
    <div class="modal-left" [class.tab-active]="tabs === 0">
      <div class="sign-container">
        <div class="sign-body">
          <perfect-scrollbar *ngIf="filledDocument" class="form-fields-box">
            <div class="title">Afegeix els signants</div>
            <div class="detail-container">
              <div class="detail-title">Signa el document</div>
              <div class="detail-text">Introdueïx els correus electrònics dels signants. Rebràn un e-mail
                amb les instruccions per a signar el document.
              </div>
            </div>
            <ng-container *ngFor="let item of filledDocument.pendingSignatures">
              <label class="label-email">Correu electrònic</label>
              <div class="input-custom">
                <input type="text" placeholder="exemple@email.com" [(ngModel)]="item.email"
                       [disabled]="isSigned(item.id)">
                <button (click)="sendAssignEmail(item)" *ngIf="!isSigned(item.id)"
                        [disabled]="tempLoading[item.id] || (!tempLoading[item.id] && tempCheck[item.id])">
                  <span *ngIf="!tempLoading[item.id] && !tempCheck[item.id]">Enviar</span>
                  <span *ngIf="tempLoading[item.id]">Enviant...</span>
                  <span *ngIf="!tempLoading[item.id] && tempCheck[item.id]">Enviat</span>
                </button>
                <button class="opacity" *ngIf="isSigned(item.id)">Ja signat</button>
              </div>
            </ng-container>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
    <div class="modal-right" [class.tab-active]="tabs === 1">
      <div class="box-inside-modal-right">
        <div class="loading" *ngIf="loading">
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="gap-patch">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </div>
        <mc-doc-preview *ngIf="document && !isResponsive && !filledDocument?.annexes.length && !filledDocument?.overwriteDocument"
                        #preview
                        [document]="document"
                        [loadAnimation]="feedbackDownload"
                        (download)="download()"
                        (languageChange)="onChangeDocumentLanguage($event)"
                        (loaded)="updateWholeDocument()"></mc-doc-preview>
        <div *ngIf="(isResponsive || filledDocument?.annexes.length || filledDocument?.overwriteDocument) && pdfUrl"
             class="preview-box-external">
          <pdf-viewer [src]="pdfUrl" [c-maps-url]="'assets/cmaps/'"
                      [render-text]="true"
                      [original-size]="false"
                      [autoresize]="true"
                      style="display: block;">
          </pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</div>
