<div class="mil-modal">
    <div class="tabs">
        <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Cercador</div>
        <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Resultats
            <span class="number-badge">{{documents.length}}</span>
        </div>
    </div>
    <div class="modal-box-split">
        <div class="modal-left" [class.tab-active]="tabs === 0">
            <h1 class="title">Troba el teu document</h1>
            <div class="loaded-spinner" *ngIf="!loaded">
                <div class="loading">
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div><div class="gap-patch">
                            <div class="circle"></div>
                        </div><div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-container">
                <app-expert-system (changeToLegalTree)="resetListDocs();"
                                   (loaded)="loaded = true" *ngIf="!displayLawyerTree">
                </app-expert-system>
                <app-lawyer-tree (changeToExpertSystem)="resetListDocs();"
                                 (loaded)="loaded = true" *ngIf="displayLawyerTree">
                </app-lawyer-tree>
            </div>
        </div>
        <div class="modal-right right-side-docs" [class.tab-active]="tabs === 1">
            <app-documents-list *ngIf="!documentSelected"></app-documents-list>
        </div>
    </div>
</div>
