<div *ngIf="documentLoaded" class="background content-page">
  <div *ngIf="!documents.length" class="no-documents-message">No hi ha documents.</div>
  <perfect-scrollbar class="document-list" #pScrollbar *ngIf="documents.length > 0">
    <virtual-scroll class="virtual-scroll" #scroll [items]="documents"
                    (update)="viewPortItems = $event"
                    (change)="onDetectionScrollChange($event)"
                    [parentScroll]="pScrollbar.directiveRef.elementRef.nativeElement">
      <ng-container *ngFor="let item of scroll.viewPortItems">
        <div class="list-item" *ngIf="!(filter || filter.length) || (item.customTitle && item.customTitle.toLowerCase().includes(filter))">
          <div class="list-item-body">
            <h6 class="list-title">{{item.customTitle}}</h6>
            <div class="list-type">{{item.subtitle}}</div>
          </div>
          <div class="list-item-footer">
            <div class="list-status">Finalitzat el {{item.completionDate | date: 'dd/MM/yyyy'}}</div>
            <div class="list-item-buttons">
              <button class="waves-effect waves-light btn-outline" (click)="docToView(item)">Ver</button>
              <button class="waves-effect waves-light btn-outline"
                      (click)="download(item.id)">{{downloadingId === item.id ? 'Descarregant...' : 'Descargar'}}</button>
            </div>
          </div>
        </div>
      </ng-container>
    </virtual-scroll>
  </perfect-scrollbar>
</div>
