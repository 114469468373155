import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {Subscription} from 'rxjs';


@Component({
  selector: 'app-auth-management',
  templateUrl: './auth-management.component.html',
  styleUrls: ['./auth-management.component.scss']
})
export class AuthManagementComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  mode: string;

  private routeSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe(async params => {
      this.mode = params['mode'];
      this.loading = false;
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
