import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {UserService} from 'milcontratos';
import {User} from 'firebase';

import {AlertService} from '../shared/services/alert.service';


@Component({
    selector: 'app-transition',
    templateUrl: './transition.component.html',
    styleUrls: ['./transition.component.scss']
})
export class TransitionComponent implements OnInit, OnDestroy {

    private returnUrl: string;
    private userSubscription: Subscription;
    private activatedRouteSubscription: Subscription;
    private user: User | null;
    private token: string;
    emailIsSent: boolean = false;
    sendingEmail: boolean = false;
    showEmailVerification = false;

    constructor(private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                private userService: UserService,
                private route: ActivatedRoute,
                private router: Router) { }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.activatedRouteSubscription = this.activatedRoute.queryParams.subscribe(async params => {
            this.token = params['t'];
        });
        this.userSubscription = this.userService.getCurrentUserObs().subscribe(async (res) => {
            if (res) {
                if (res.isAnonymous) {
                    await this.userService.signOut();
                    await this.router.navigate(['sign-in']);

                } else {
                    this.user = res;

                    if (this.returnUrl !== '/') {
                        await this.router.navigateByUrl(this.returnUrl);
                    } else {
                        await this.router.navigate(['home']);
                    }
                }
            } else {
                if (this.token !== undefined) {
                    try {
                        const user = await this.userService.signInPimec(this.token, '', '');
                        if (user) {
                            await this.router.navigate(['home']);
                        }
                    } catch (e) {
                        console.log(e);
                        await this.router.navigate(['sign-in']);
                    }
                } else {
                    await this.router.navigate(['sign-in']);
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.activatedRouteSubscription) {
            this.activatedRouteSubscription.unsubscribe();
        }
    }

    async onSendVerificationEmail() {
        if (!this.sendingEmail) {
            try {
                this.sendingEmail = true;
                await this.userService.sendEmailVerificationPlatform();
                this.emailIsSent = true;
                this.sendingEmail = false;
            } catch (e) {
                console.error(e);
                await this.alertService.showAlert();
                this.sendingEmail = false;
            }
        }
    }
}
