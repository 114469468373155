<div class="center-align" *ngIf="!loading && !tokenError">
  <i  class="material-icons notranslate successIcon">check</i>
  <div class="title">Email verificado con éxito.</div>
</div>
<div class="redirect-buttons-container">
  <div *ngIf="!loading && !tokenError" class="redirect-button" (click)="redirectToMyDocuments()">
    <i class="material-icons notranslate prefix eye-icon">remove_red_eye</i>
    Ir a mis documentos
  </div>
  <div *ngIf="!loading && !tokenError" class="redirect-button" (click)="redirectToSearchDocuments()">
    <i class="material-icons notranslate prefix eye-icon">remove_red_eye</i>
    Ir a encuentra tu documento
  </div>
</div>
<div class="center-align" *ngIf="!loading && tokenError">
  <i  class="material-icons notranslate errorIcon">clear</i>
  <div class="title">No hemos podido verificar tu email. Para hacerlo debes tener la
    <a class="link" href="/sign-in" (click)="redirectToLogin($event)">sesión iniciada</a>.
    Con la sesión iniciada vuelve a clicar el botón del email que te hemos enviado.</div>
</div>
