import {Injectable} from '@angular/core';

import {EnvironmentBaseService} from 'milcontratos';

import {environment} from '../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class EnvironmentService extends EnvironmentBaseService {
    private selectedPlatformId: string = environment.platformId;

    constructor() {
        super();
    }

    bucket(): string {
        return environment.bucket;
    }

    firebaseOptions(): any {
        return environment.firebase;
    }

    urlFunctions(): string {
        return environment.urlFunctions;
    }

    setPlatformId(id: string): void {
        this.selectedPlatformId = id;
    }

    platformId(): string {
        return environment.platformId;
    }
}
