import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';
import {DocumentsService} from 'milcontratos';
import {Subject, Subscription} from 'rxjs';

import {LoadedDocumentsService} from './loaded-documents.service';
import {PublicLegalDocument} from 'milcontratos-database';


@Component({
    selector: 'app-search-doc',
    templateUrl: './search-doc.component.html',
    styleUrls: ['./search-doc.component.scss']
})
export class SearchDocComponent implements OnInit, OnDestroy {

    @ViewChild('searchBox') searchBox: PerfectScrollbarComponent;

    private activatedRouteSubscription: Subscription;
    private listDocumentsSubscription: Subscription;
    private selectedDocumentSubscribe: Subscription;

    private _selectedComponentSubject: Subject<string> = new Subject();
    selectedComponentObs = this._selectedComponentSubject.asObservable();

    documents: PublicLegalDocument[] = [];
    documentSelected: PublicLegalDocument;
    tabs: number = 0;

    loaded: boolean = false;

    displayLawyerTree: boolean = false;

    constructor(private loadedDocumentsService: LoadedDocumentsService,
                private documentsService: DocumentsService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {}

    async ngOnInit() {
        const currentUrl = this.router.url.split('/');
        this.setSelectedComponent('expert-system');

        this.listDocumentsSubscription = this.loadedDocumentsService.documentsListObservable().subscribe(res => {
            this.documents = res;
        });

        // this.selectedDocumentSubscribe = this.loadedDocumentsService.selectedDocumentObservable().subscribe(async res => {
        //   this.documentSelected = await this.documentsService.getPublicDocumentBytextId(res);
        // });

        if (currentUrl[1] === 'lawyer-tree') {
            this.setSelectedComponent('lawyer-tree');
            this.displayLawyerTree = true;
        } else if (currentUrl[1] !== 'lawyer-tree' && currentUrl[1] !== 'search-document') {
            this.tabs = 1;
        }
    }

    ngOnDestroy() {
        // if (this.selectedDocumentSubscribe) {
        //   this.selectedDocumentSubscribe.unsubscribe();
        // }
        if (this.listDocumentsSubscription) {
            this.listDocumentsSubscription.unsubscribe();
        }
        if (this.activatedRouteSubscription) {
            this.activatedRouteSubscription.unsubscribe();
        }
    }

    setSelectedComponent(selected: string) {
        this._selectedComponentSubject.next(selected);
    }

    /**
     * Side effect: sets the search tab
     */
    async resetListDocs() {
        this.loaded = false;
        this.loadedDocumentsService.setQuestions([]);
        this.loadedDocumentsService.setSelectedResponse([]);
        this.loadedDocumentsService.resetDocumentsList();
        this.loadedDocumentsService.setSelectedDocument(undefined);
        this.tabs = 0;
        if (this.displayLawyerTree) {
            this.displayLawyerTree = false;
            this.setSelectedComponent('expert-system');
            await this.router.navigate(['search-document']);
        } else {
            this.displayLawyerTree = true;
            this.setSelectedComponent('lawyer-tree');
            await this.router.navigate(['lawyer-tree']);
        }
    }

    async deselectDocument() {
        if (this.displayLawyerTree) {
            await this.router.navigate( ['lawyer-tree']);
        } else {
            await this.router.navigate(['search-document']);
        }
    }
}
