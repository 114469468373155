import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import {HttpModule} from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { VirtualScrollModule } from 'angular2-virtual-scroll';
import { MilcontratosModule } from 'milcontratos';
import { MaterializeModule } from '@samuelberthe/angular2-materialize';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AutosizeModule } from 'ngx-autosize';
import { AngularFireModule } from 'angularfire2';
import { ShowdownModule } from 'ngx-showdown';
import {PdfViewerModule} from 'ng2-pdf-viewer';

import { AppComponent } from './app.component';
import { FeedComponent } from './feed/feed.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FootbarComponent } from './footbar/footbar.component';
import { SearchDocComponent } from './search-doc/search-doc.component';
import { DocumentsListComponent } from './search-doc/documents-list/documents-list.component';
import { DocumentInfoComponent } from './document-info/document-info.component';
import { ChatComponent } from './chat/chat.component';
import { MyDocumentsComponent } from './my-documents/my-documents.component';
import { KeysPipe } from './shared/pipes/keys.pipe';
import { SanitizerPipe } from './shared/pipes/sanitizer.pipe';
import { EnvironmentService } from './environment.service';
import { environment } from '../environments/environment';
import { appRouting } from './app.routing';
import { DocumentsCompletedComponent } from './my-documents/documents-completed/documents-completed.component';
import { DocumentsTemplateComponent } from './my-documents/documents-template/documents-template.component';
import { SignDocumentComponent } from './my-documents/sign-document/sign-document.component';
import { DocumentsDraftComponent } from './my-documents/documents-draft/documents-draft.component';
import { AuthService } from './shared/services/auth/auth.service';
import { AddSignersComponent } from './my-documents/add-signers/add-signers.component';
import { TransitionComponent } from './transition/transition.component';
import { WaitingSignersComponent } from './my-documents/waiting-signers/waiting-signers.component';
import { CompletedDocumentComponent } from './completed-document/completed-document.component';
import { FillingDocumentComponent } from './filling-document/filling-document.component';
import { CookiesComponent } from './cookies/cookies.component';
import { PrivacityPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DocumentsReviewingComponent } from './my-documents/documents-reviewing/documents-reviewing.component';
import { LawyerTreeComponent } from './search-doc/lawyer-tree/lawyer-tree.component';
import { LawyerBranchesComponent } from './search-doc/lawyer-tree/lawyer-branches/lawyer-branches.component';
import { ExpertSystemComponent } from './search-doc/expert-system/expert-system.component';
import {SignInComponent} from './sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { SupportComponent } from './support/support.component';
import { LegalWarningComponent } from './legal-warning/legal-warning.component';
import {PreviousRouteService} from './previous-route.service';
import {VerifyEmailComponent} from './auth-management/verify-email/verify-email.component';
import {ResetPasswordComponent} from './auth-management/reset-password/reset-password.component';
import {AuthManagementComponent} from './auth-management/auth-management.component';
//If(documents == 'payment')
import {BuyDocumentComponent} from './buy-document/buy-document.component';
import {PurchaseDocumentCompletedComponent} from './purchase-document-completed/purchase-document-completed.component';
import {MyPurchasesComponent} from './my-purchases/my-purchases.component';
import { CardPaymentComponent } from './card-payment/card-payment.component';


//Endif


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: false
};

@NgModule({
    declarations: [
        AppComponent,
        FeedComponent,
        NavbarComponent,
        FootbarComponent,
        SearchDocComponent,
        DocumentsListComponent,
        DocumentInfoComponent,
        KeysPipe,
        SanitizerPipe,
        ChatComponent,
        MyDocumentsComponent,
        DocumentsCompletedComponent,
        DocumentsTemplateComponent,
        SignDocumentComponent,
        DocumentsDraftComponent,
        AddSignersComponent,
        TransitionComponent,
        WaitingSignersComponent,
        CompletedDocumentComponent,
        FillingDocumentComponent,
        CookiesComponent,
        PrivacityPolicyComponent,
        DocumentsReviewingComponent,
        LawyerTreeComponent,
        LawyerBranchesComponent,
        ExpertSystemComponent,
        SignInComponent,
        HomeComponent,
        SupportComponent,
        LegalWarningComponent,
        VerifyEmailComponent,
        ResetPasswordComponent,
        AuthManagementComponent,
        BuyDocumentComponent,
        PurchaseDocumentCompletedComponent,
        MyPurchasesComponent,
        CardPaymentComponent
    ],
    imports: [
        FormsModule,
        BrowserModule,
        MaterializeModule,
        HttpClientModule,
        HttpModule,
        VirtualScrollModule,
        AngularFireModule.initializeApp(environment.firebase),
        MilcontratosModule.fromRoot(EnvironmentService),
        PerfectScrollbarModule,
        ShowdownModule,
        appRouting,
        AutosizeModule,
        DeviceDetectorModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        PdfViewerModule,
    ],
    providers: [AuthService, PreviousRouteService, {
        provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }],
    bootstrap: [AppComponent]
})
export class AppModule {}
