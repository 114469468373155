<div class="background">
  <router-outlet></router-outlet>
  <app-chat></app-chat>
</div>
<app-footbar></app-footbar>

<div class="cookies"
     *ngIf="showCookiesBanner">
  <div class="cookies-text-box">
    <div class="cookies-text">
        Utilitzem cookies pròpies i de tercers per a obtenir estadístiques de la navegació amb la finalitat de millorar
        els nostres serveis i l’experiència d’usuari. Si continues navegant, considerem que n’acceptes el seu ús.
        Pots configurar l’ús de les teves cookies des del teu navegador. Pots consultar més informació
        <span (click)="showMoreInfo()">aquí</span>.
    </div>
  </div>
  <button type="button"
          class="waves-effect waves-light btn"
          (click)="acceptCookies()">ACCEPTAR</button>
</div>
