<div *ngIf="!openChat && (userObservable | async)" class="chat-button" (click)="openChatWindow()">
  <svg width="53px" height="53px" viewBox="10 6 56 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 49.3 (51167) - http://www.bohemiancoding.com/sketch -->
    <title>Chat icon</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <rect id="path-1" x="0" y="0" width="53" height="53" rx="26.5"></rect>
      <filter x="-39.6%" y="-28.3%" width="179.2%" height="179.2%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.062745098   0 0 0 0 0.105882353   0 0 0 0 0.309803922  0 0 0 0.20533288 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
    </defs>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Chat-icon" transform="translate(12.000000, 6.000000)">
        <g>
          <g id="Rectangle-6-Copy">
            <use class="chat-background-fill" fill-rule="evenodd" xlink:href="#path-1"></use>
          </g>
          <g id="Page-1" transform="translate(14.620690, 17.362069)">
            <path class="chat-text-fill" d="M16.3731925,0 L7.38550431,0 C3.32346552,0 0,3.38243758 0,7.51647629 L0,8.99115832 C0,13.125197 3.32346552,16.5076346 7.38550431,16.5076346 L13.9412083,16.5076346 L13.9376293,21.0172414 L20.623092,15.1288984 C22.5165474,13.7644997 23.7586207,11.5183764 23.7586207,8.99115832 L23.7586207,7.51647629 C23.7586207,3.38243758 20.4351552,0 16.3731925,0" id="Fill-1"></path>
            <path class="chat-icon-dots" d="M8.22413793,8.68103448 C8.22413793,9.43802664 7.61044043,10.0517241 6.85352819,10.0517241 C6.09645612,10.0517241 5.48275862,9.43802664 5.48275862,8.68103448 C5.48275862,7.92404233 6.09645612,7.31034483 6.85352819,7.31034483 C7.61044043,7.31034483 8.22413793,7.92404233 8.22413793,8.68103448" id="Fill-4"></path>
            <path class="chat-icon-dots" d="M13.7068966,8.68103448 C13.7068966,9.43802664 13.093199,10.0517241 12.3362868,10.0517241 C11.5792147,10.0517241 10.9655172,9.43802664 10.9655172,8.68103448 C10.9655172,7.92404233 11.5792147,7.31034483 12.3362868,7.31034483 C13.093199,7.31034483 13.7068966,7.92404233 13.7068966,8.68103448" id="Fill-6"></path>
            <path class="chat-icon-dots" d="M19.1896552,8.68103448 C19.1896552,9.43802664 18.5759398,10.0517241 17.8190055,10.0517241 C17.0619113,10.0517241 16.4482759,9.43802664 16.4482759,8.68103448 C16.4482759,7.92404233 17.0619113,7.31034483 17.8190055,7.31034483 C18.5759398,7.31034483 19.1896552,7.92404233 19.1896552,8.68103448" id="Fill-8"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
<div *ngIf="openChat && (userObservable | async)" class="chat-button" (click)="openChat = false;">
  <svg width="53px" height="53px" viewBox="10 6 56 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 49.3 (51167) - http://www.bohemiancoding.com/sketch -->
    <title>Chat icon</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <rect id="path-1" x="0" y="0" width="53" height="53" rx="26.5"></rect>
      <filter x="-39.6%" y="-28.3%" width="179.2%" height="179.2%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="6" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0.062745098   0 0 0 0 0.105882353   0 0 0 0 0.309803922  0 0 0 0.20533288 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
      </filter>
      <polygon id="path-3" points="15 1.51071429 13.4892857 0 7.5 5.98928571 1.51071429 0 0 1.51071429 5.98928571 7.5 0 13.4892857 1.51071429 15 7.5 9.01071429 13.4892857 15 15 13.4892857 9.01071429 7.5"></polygon>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="7---Mi-cuenta.-/-Mis-documentos" transform="translate(-1308.000000, -788.000000)">
        <g id="Chat-icon" transform="translate(1320.000000, 794.000000)">
          <g id="Rectangle-6-Copy">
            <use class="chat-background-fill" fill-rule="evenodd" xlink:href="#path-1"></use>
          </g>
          <g id="ic_close" transform="translate(20.000000, 20.000000)">
            <mask id="mask-4" fill="white">
              <use xlink:href="#path-3"></use>
            </mask>
            <use class="chat-text-fill" id="Mask" xlink:href="#path-3"></use>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
<div *ngIf="openChat && (userObservable | async)" class="chat-box">
  <div *ngIf="userAdmin" class="chat-header">
    <div class="chat-image-box">
      <img *ngIf="userAdmin.avatar" [src]="userAdmin.avatar" alt="user's avatar">
    </div>
    <div>{{userAdmin.username}}</div>
  </div>
  <div *ngIf="!noSupportAvailable && (!userAdmin || !(userObservable | async))" class="chat-header">
    <h4>Indica'ns els teus dubtes</h4>
  </div>
  <div *ngIf="noSupportAvailable" class="chat-header-grey">
    <div>En aquest moment no hi som</div>
  </div>
  <perfect-scrollbar #chatBox class="chat-text-box">
    <div *ngIf="(userObservable | async)">
      <div [ngClass]="{'admin-message': !message.isFromClient, 'user-message': message.isFromClient}"
           *ngFor="let message of groupMessages">
        <div *ngFor="let text of message.text">
          {{text}}
        </div>
      </div>
      <div class="admin-message" *ngIf="noSupportAvailable">
        Ho sentim, ara mateix no estem disponibles. Envia'ns un correu amb els teus dubtes mitjançant el
        <span class="text-display-chat"><a [routerLink]="['/support']">formulari de suport</a></span>
      </div>
      <div class="admin-message" *ngIf="noSupportAvailable">
        El nostre horari d'atenció al soci és de dilluns a dijous de 9 a 18h i els divendres de 9 a 14h.
      </div>
    </div>
  </perfect-scrollbar>
    <div *ngIf="userAdmin && (userObservable | async) && adminIsWriting" class="chat-writing-message">
        {{userAdmin.username}} està escrivint...
    </div>
  <textarea autosize *ngIf="(userObservable)" class="new-message" placeholder="Escriu aquí per a obtenir ajuda"
            [(ngModel)]="textareaEmpty"
            (keyup)="setIsUserTyping(true)"
            (keyup.shift.enter)="empty($event.target.value)"
            (keydown.enter)="$event.preventDefault();"
            (keyup.enter)="sendMessage($event.target.value)"></textarea>
  <button type="button" class="waves-effect waves-dark btn send-message-button" (click)="sendMessage(textareaEmpty)">ENVIAR</button>
</div>
