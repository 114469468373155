<div class="mil-modal">
    <div class="tabs">
        <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Document</div>
        <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Visualització</div>
    </div>
    <div *ngIf="document" class="modal-box-split">
        <div class="modal-left" [class.tab-active]="tabs === 0">
            <div class="document-data-container">
                <div class="go-back" (click)="unselectDocument()">
                    <i class="material-icons notranslate">keyboard_arrow_left</i>
                    Enrrere
                </div>
                <h1 class="doc-title">{{document.name}}</h1>
                <p *ngIf="languages[document.documentLanguage]" class="document-languages">
                    {{(mainLanguageDocument.availableLanguages | keys).length > 1 ? 'Idiomes:' : 'Idioma:'}}
                    <a [routerLink]="['/legal-document', mainLanguageDocument.textId, mainLanguageDocument.documentLanguage]"
                       class="{{languages[mainLanguageDocument.documentLanguage] ? 'flag-icon flag-icon-' + languages[mainLanguageDocument.documentLanguage].flag : ''}} {{documentLanguage === mainLanguageDocument.documentLanguage? 'selected-flag':''}}">
                    </a>
                    <ng-container *ngFor="let language of mainLanguageDocument.availableLanguages | keyvalue">
                      <a *ngIf="language.key !== mainLanguageDocument.documentLanguage"
                         [routerLink]="['/legal-document', mainLanguageDocument.textId, language.key]"
                         class="{{languages[language.key] ? 'flag-icon flag-icon-' + languages[language.key].flag : '' }} {{documentLanguage === language.key? 'selected-flag':''}}">
                      </a>
                    </ng-container>
                  </p>
                <h3 class="doc-type">{{document.short_description}}</h3>
                <div class="price-box">
                    <!--@if(documents == 'payment')-->
                    <div class="price-inside-box">
                        <div class="price-text">Preu del document:</div>
                        <div class="price-number">
                            {{ state === documentStates.freeStaticDownload ? 'Gratis' : (price | number : '1.2-2') + '€'}}
                            <span *ngIf="state !== documentStates.freeStaticDownload">
                              ({{ priceIva | number : '1.2-2'}}€ con <abbr title="Impuesto sobre el Valor Añadido">IVA</abbr>)
                            </span>
                        </div>
                    </div>
                    <!--@endif-->
                    <button *ngIf="state === documentStates.owned"
                            type="button" class="waves-effect waves-light btn pay-button"
                            (click)="redirectFillDocument()">Emplenar</button>
                    <!--@if(documents == 'free')-->
                    <!--<button *ngIf="state === documentStates.buy"
                            type="button" class="waves-effect waves-light btn pay-button"
                            [disabled]="blockBuyButton"
                            (click)="buyDocument()">Rellenar1</button>-->
                    <!--@endif-->
                    <!--@if(documents == 'payment')-->
                    <button *ngIf="state === documentStates.buy"
                            type="button" class="waves-effect waves-light btn pay-button"
                            (click)="buyDocument()">Obtenir</button>
                    <!--@endif-->
                    <button *ngIf="state === documentStates.freeStaticDownload"
                            type="button" class="waves-effect waves-light btn pay-button" [disabled]="downloading"
                            (click)="startDownloadStaticDocuments()">{{downloading ? 'Descarregant' : 'Descarregar' }}</button>
                </div>
            </div>
            <showdown class="doc-long-description" [options]="options" [value]="document.long_description"></showdown>
        </div>
        <div class="modal-right right-side-docs" [class.tab-active]="tabs === 1">
            <div *ngIf="!document?.isStaticDocument" class="partial-preview-message">
                Previsualització parcial del document
            </div>
            <img *ngIf="!document?.isStaticDocument && documentPreviewUrl"
                 [src]="documentPreviewUrl"
                 [alt]="'previsualització del document ' + document.textId"/>
            <img *ngIf="document?.isStaticDocument"
                 [src]="'../../assets/model_preview.png'"
                 [alt]="'previsualització del document ' + document.textId"/>
        </div>
    </div>
</div>
