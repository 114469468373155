<div class="mil-modal">
  <div class="modal-box-split">
    <div class="modal-left">
      <h1 class="title">Ajuda</h1>
      <div class="sign-in-form">
        <form>
          <label for="name" class="input-label">Nom i cognoms</label>
          <div class="sign-in-field input-custom">
            <input id="name" name="name" type="text" class="input-text" (keyup)="name = $event.target.value"/>
          </div>
          <label for="email" class="input-label">Correu electrònic</label>
          <div class="sign-in-field input-custom">
            <input id="email" name="email" type="text" class="input-text" (keyup)="email = $event.target.value"/>
          </div>
          <div class="select-option-box">
            <label for="issue" class="input-label">Assumpte</label>
            <select id="issue" name="issue" [ngModel]="selectedIssue" (ngModelChange)="selectedIssue = $event"
                    materialize="FormSelect"
                    [materializeSelectOptions]="issue">
              <option [value]="undefined" disabled>Selecciona una opció ...</option>
              <option *ngFor="let option of issue" [value]="option">{{option}}</option>
            </select>
          </div>
          <label for="message" class="input-label margin-top">Missatge</label>
          <div class="sign-in-field textarea-input-custom">
            <textarea id="message" name="message"
                      [(ngModel)]="message"
                      (blur)="message = $event.target.value"></textarea>
          </div>
          <div class="align-center">
            <button type="submit" class="waves-effect waves-light btn sign-in-enter" (click)="send()">Envia</button>
            <div *ngIf="error" class="error-message">Tots els camps són necessaris.</div>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-right sign-image align-center">
    </div>
  </div>
</div>
