import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import paypal from 'paypal-checkout';
import {Subscription} from 'rxjs';
import {MaterializeAction} from '@samuelberthe/angular2-materialize';
import {
  DocumentsService,
  LegalAdviceService,
  UserService
} from 'milcontratos';
import {
  LegalAdviceMeta, PublicLegalDocument,
} from 'milcontratos-database';

import {SearchDocumentService} from '../shared/services/search-document/search-document.service';
import {round} from '../shared/utils/utils';
import {ChatStateService} from '../shared/services/chat/chat-state.service';
import {AlertService} from '../shared/services/alert.service';
import {environment} from '../../environments/environment';
import * as rxp from 'rxp-js';
import {Payer} from '../card-payment/payer';



@Component({
  selector: 'app-buy-document',
  templateUrl: './buy-document.component.html',
  styleUrls: ['./buy-document.component.scss']
})
export class BuyDocumentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('paypalButtonContainer') paypalButtonContainer: ElementRef;

  private routeSubscription: Subscription;
  private userSubscription: Subscription;
  private documentId: string;
  private backClicked: boolean = false;
  private iva: number;

  discoverPopup: boolean = false;

  legalAdvicePrice: number;
  legalAdviceAdded: boolean = false;
  document: PublicLegalDocument;
  price: number;
  ivaPrice: number;
  legalAdviceIvaPrice: number;
  modalActions: EventEmitter<string|MaterializeAction> = new EventEmitter<string|MaterializeAction>();

    payer: Payer;

    loading: boolean = true;
  blockBuyButton: boolean = false;
  displayDealInput: boolean = false;
  isDocumentOwned: boolean = false;
  loadingPayButtons: boolean = true;

  constructor(private alertService: AlertService,
              private chatState: ChatStateService,
              private documentsService: DocumentsService,
              private legalAdviceService: LegalAdviceService,
              private route: ActivatedRoute,
              private router: Router,
              private searchDocumentService: SearchDocumentService,
              private userService: UserService) { }

  ngAfterViewInit() {
    this.userSubscription = this.userService.getCurrentUserObs().subscribe(async (us) => {
      if (us) {
        this.loadingPayButtons = true;
          this.loadingPayButtons = false; //try disabling for for production mode

          console.log('inside ngAfterViewInit');

          // sample code using milcontratos' backend.
        paypal.Button.render({
          style: {
            size: 'medium',
            label: 'pay',
            tagline: false,
          },
          env: environment.paypalMode, // sandbox | production

          // Show the buyer a 'Pay Now' button in the checkout flow
          commit: true,

          // payment() is called when the button is clicked
          payment: await this.documentsService.getStartPaypalPromotion(this.documentId, this.legalAdviceAdded, false,
            undefined, undefined, (e) => this.openModal(e)
          ),

          // onAuthorize() is called when the buyer approves the payment
          onAuthorize: await this.documentsService.getCompletePaypalPromotion(
            (err, response) => {
              if (err) {
                this.openModal();
              } else {
                this.router.navigate(['/purchase-document-completed', this.documentId]);
              }
            }
          ),

        }, '#paypal-button-container');
        //begins Card Payment
          const jsonFromServerSdk = await this.documentsService.getRealexHPP(this.documentId, false,
              undefined, false,
              undefined);
          if (environment.paypalMode === 'sandbox') {
              rxp.RealexHpp.setHppUrl('https://hpp.sandbox.addonpayments.com/pay');
          } else {
              rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
          }
          console.log('let\'s se where it redirects me');
          rxp.RealexHpp.init('cardPayment', environment.baseUrl + '/compra-documento-completada/' + this.documentId, jsonFromServerSdk);
          this.loadingPayButtons = false;
          //ends Card Payment
      }
    });
  }

  async ngOnInit() {
    try {
      this.routeSubscription = this.route.params.subscribe((params) => {
        this.documentId = params['document_id'];
      });
      //Begins Card Payment
        this.route.queryParams.subscribe(async (params) => {
            if (params['error']) {
                if (params['error'] < 200) {
                    await this.alertService
                        .showAlert('Operació denegada. Per favor, torni a intentar-ho o provi un mètode alternatiu.');
                } else if (params['error'] < 300) {
                    await this.alertService.showAlert('Error amb el sistema bancari. Per favor, torni a intentar-ho ' +
                        'o provi un mètode de pagament alternatiu.');
                } else if (params['error'] < 400) {
                    await this.alertService.showAlert('Error amb la plataforma de pagament. Si us plau, torni a intentar-ho o ' +
                        'provi un mètode de pagament alternatiu.');
                } else {
                    await this.alertService.showAlert('Ha succeït un error. Si us plau, torni a intentar-ho o ' +
                        'provi un mètode de pagament alternatiu');
                }
            }
        });
        //Ends Card Payment
      this.iva = await this.documentsService.getIVA();
      this.document = await this.documentsService.getPublicDocumentById(this.documentId);
      this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      const legalAdvice: LegalAdviceMeta = await this.legalAdviceService.getLegalAdviceDocument();
      this.legalAdvicePrice = legalAdvice.price;
      this.legalAdviceIvaPrice = round(legalAdvice.price + legalAdvice.price * (this.iva / 100), 2);
      this.loading = false;
    } catch (e) {
      console.error(e);
      await this.alertService.showAlert();
    }
  }

  ngOnDestroy() {
    if (!this.backClicked) {
      this.searchDocumentService.setComesFromBack(false);
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async addLegalAdvice() {
    try {
      this.loadingPayButtons = true;
      this.legalAdviceAdded = true;
      this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id, this.legalAdviceAdded,
        false, undefined);
      this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id, this.legalAdviceAdded,
        false, undefined);
      await this.reRenderPaypal();
    } catch (e) {
      console.log(e);
      this.loadingPayButtons = false;
      await this.alertService.showAlert();
    }
  }

  async removeLegalAdvice() {
    try {
      this.loadingPayButtons = true;
      this.legalAdviceAdded = false;
      this.price = await this.documentsService.getDocumentPriceWithoutIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      this.ivaPrice = await this.documentsService.getDocumentPriceWithIva(this.document.version_id,
        this.legalAdviceAdded, false, undefined);
      await this.reRenderPaypal();
    } catch (e) {
      console.log(e);
      this.loadingPayButtons = false;
      await this.alertService.showAlert();
    }
  }

  openModal(e?) {
    this.isDocumentOwned = false;
    const error = e.message.match(/{([\s\S]*?)^}/gm);
    if (JSON.parse(error[0]).message === 'Already owns document') {
      this.isDocumentOwned = true;
    }
    this.modalActions.emit({action: 'open', params: []});
  }

  async reRenderPaypal() {
    this.loadingPayButtons = true;
      console.log('reRenderPaypal executed');

      for (const child of this.paypalButtonContainer.nativeElement.children) {
      child.remove();
    }

    // sample coding using milcontratos' backend
    paypal.Button.render({
      style: {
        size: 'medium',
        label: 'pay',
        tagline: false,
      },
      env: environment.paypalMode, // sandbox | production

      // Show the buyer a 'Pay Now' button in the checkout flow
      commit: true,

      // payment() is called when the button is clicked
      payment: await this.documentsService.getStartPaypalPromotion(this.documentId, this.legalAdviceAdded, false,
          undefined, undefined, (err) => this.openModal()),

      // onAuthorize() is called when the buyer approves the payment
      onAuthorize: await this.documentsService.getCompletePaypalPromotion(
        (err, response) => {
          if (err) {
            this.openModal();
          } else {
            this.router.navigate(['/compra-documento-completada', this.documentId]);
          }
        }
      ),
    }, '#paypal-button-container');
      //begins Credit Card Payment
          const jsonFromServerSdk = await this.documentsService.getRealexHPP(this.documentId, false,
               undefined, this.legalAdviceAdded,
               undefined);
          if (environment.paypalMode === 'sandbox') {
              rxp.RealexHpp.setHppUrl('https://hpp.sandbox.addonpayments.com/pay');
          } else {
              rxp.RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
          }
          console.log('envinronment baseUrl: ', environment.baseUrl);
          rxp.RealexHpp.init('cardPayment',  environment.baseUrl + '/compra-documento-completada/' + this.documentId, jsonFromServerSdk);
          //ends Credit Card Payment
    this.loadingPayButtons = false;
  }

  async goBack() {
    this.searchDocumentService.setComesFromBack(true);
    this.backClicked = true;
    await this.router.navigate(['/search-document']);
  }

  completePaymentCard(completed: boolean) {
    if (completed) {
        console.log('inside completePaymentCard completed === true');
      this.router.navigate(['/compra-documento-completada', this.documentId]);
    }
  }

  displayChat() {
    this.chatState.changeState(true);
  }
}
