import {Component, OnInit} from '@angular/core';
import {Http, RequestOptions, ResponseContentType} from '@angular/http';
import {Router} from '@angular/router';

import {DocumentsService, OwnedDocumentsService} from 'milcontratos';
import {saveAs} from 'file-saver';
import {FilledDocument} from 'milcontratos-database';
import {ChangeEvent} from 'angular2-virtual-scroll';

import {AlertService} from '../../shared/services/alert.service';


@Component({
  selector: 'app-documents-completed',
  templateUrl: './documents-completed.component.html',
  styleUrls: ['./documents-completed.component.scss']
})
export class DocumentsCompletedComponent implements OnInit {

  documents: FilledDocument[] = [];
  cursorPage: any;
  filter: string = '';
  private loadingDocs: boolean = true;

  downloadingId: string = '';

  documentLoaded: boolean = false;

  constructor(private alertService: AlertService,
              private docService: DocumentsService,
              private http: Http,
              private ownedServices: OwnedDocumentsService,
              private router: Router) { }

  async ngOnInit() {
    await this.getOwnedCompletedDocuments();
  }

  docToView(document: FilledDocument) {
    this.router.navigate(['document'], { queryParams: { document_id: document.document_id, id: document.id } });
  }

  async getOwnedCompletedDocuments() {
    try {
      const doc = await this.ownedServices.getPaginateCompletedFilledDocuments(10, this.cursorPage);
      this.cursorPage = doc.cursor;
      this.documents = doc.documents;
      this.documentLoaded = true;
      this.loadingDocs = false;
    } catch (e) {
      await this.alertService.showAlert('Hi ha hagut un error al carregar els documents.');
    }
  }

  searchDoc(value) {
    this.filter = value.toLowerCase();
  }

  async download(filledDocumentId) {
    try {
      if (!this.downloadingId) {
        this.downloadingId = filledDocumentId;
        const render = await this.ownedServices.renderCompletedFilledDocuments(filledDocumentId);
        const subscriber = this.docService.getUrlResource(render.docxPath).subscribe((url) => {
          if (url) {
            const options = new RequestOptions({responseType: ResponseContentType.Blob});
            this.http.get(url, options).subscribe(
              response => { // download file
                const blob = new Blob([response.blob()],
                  {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
                const filename = 'document.docx';
                saveAs(blob, filename);
              },
              error => {
                console.error(`Error: ${error.message}`);
              }
            );
            subscriber.unsubscribe();
          }
        });
        this.downloadingId = '';
      }
    } catch (e) {
      this.downloadingId = '';
      await this.alertService.showAlert('Hi ha hagut un error al descarregar el document.');
    }
  }

  async onDetectionScrollChange(evt: ChangeEvent) {
    try {
      if (evt.start === 0) {
        // Ir para atrás.
      } else if (evt.end === this.documents.length - 1) {
        // Ir para delante.
        if (!this.loadingDocs) {
          this.loadingDocs = true;
          const doc = await this.ownedServices.getPaginateCompletedFilledDocuments(10, this.cursorPage);
          this.cursorPage = doc.cursor;
          this.documents.push(...doc.documents);
          this.loadingDocs = false;
        }
      }
    } catch (e) {
      await this.alertService.showAlert('Hi ha hagut un error al carregar els documents.');
    }
  }
}
