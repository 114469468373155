<div *ngIf="directoryTree">
  <div *ngFor="let directory of directoryTree.directories | keys; let di = index"
       class="directory">
    <div *ngIf="directory.key" class="directory-row" (click)="selectDirectory(di, directory.value, $event)">
      <div *ngIf="di !== selectedDirectory" class="directory-plus">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 28 28">
          <defs>
            <path id="du17a" d="M222 325.5h-7.5V318h-1v7.5H206v1h7.5v7.5h1v-7.5h7.5z"/>
            <path id="du17b" d="M202 326c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
          </defs>
          <g>
            <g transform="translate(-196 -308)">
              <g>
                <g>
                  <use fill="#2b7685" xlink:href="#du17a"/><use fill="#fff" fill-opacity="0" stroke="#2b7685" stroke-miterlimit="50" xlink:href="#du17a"/>
                </g>
              </g>
              <g opacity="0"><use fill="#fed038" xlink:href="#du17b"/>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div *ngIf="di === selectedDirectory" class="directory-plus">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 28 28">
          <defs>
            <path id="cyzsa" d="M228 327.5h-16v1h16z"/>
            <path id="cyzsb" d="M208 328c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12-12-5.373-12-12z"/>
          </defs>
          <g>
            <g transform="translate(-208 -316)">
              <g>
                <use fill="#e44b48" xlink:href="#cyzsa"/>
                <use fill="#fff" fill-opacity="0" stroke="#e44b48" stroke-miterlimit="50" xlink:href="#cyzsa"/>
              </g>
              <g opacity=".3">
                <use fill="#ebebeb" xlink:href="#cyzsb"/>
              </g>
            </g>
          </g>
        </svg>
      </div>
        <a [href]="directoryHrefs[di]" class="directory-name">{{directory.key}}</a>
    </div>
    <div class="directory-branches" *ngIf="di === selectedDirectory">
      <app-lawyer-branches [directoryId]="directory.value"></app-lawyer-branches>
    </div>
  </div>
</div>
