import {Component, OnInit} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';

import {DeviceDetectorService} from 'ngx-device-detector';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isTablet: boolean = false;
  showTabletAdvice: boolean = true;

  constructor(private deviceDetectorService: DeviceDetectorService,
              private router: Router) {
    router.events.subscribe((ev: Event) => {
      if (ev instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
      this.isTablet = this.deviceDetectorService.isTablet();
    });
  }

  ngOnInit() {
    setTimeout(function () {
      try {
        const viewheight = window.innerHeight;
        const viewwidth = window.innerWidth;
        const viewport = document.querySelector('meta[name=viewport]');
        viewport.setAttribute('content', 'height=' + viewheight + 'px, width=' + viewwidth + 'px, initial-scale=1.0');
      } catch (e) {
        console.error(e);
      }
    }, 1000);
  }

  onHideTabletAdvice() {
    this.showTabletAdvice = false;
  }
}
