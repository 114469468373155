import { Injectable } from '@angular/core';

import Swal, {SweetAlertType} from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  async showAlert(msg: string = 'Ha succeït un error, si us plau, torna a intentar-ho.',
                  type: SweetAlertType = 'error',
                  confirmText: string = 'Ok',
                  title: string = 'Error!'): Promise<void> {
    await Swal({
      title: title,
      text: msg,
      type: type,
      confirmButtonText: confirmText,
      customClass: 'sweet-alert-test',
      confirmButtonColor: '#f9ed43',
      cancelButtonColor: '#f9ed43',
    });
  }
}
