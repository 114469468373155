<div class="background content-page">
  <div *ngIf="!documents.length" class="no-documents-message">No hi ha documents.</div>
  <perfect-scrollbar class="document-list" #pScrollbar *ngIf="documents.length > 0">
    <virtual-scroll class="virtual-scroll" #scroll [items]="documents" (update)="scroll.viewPortItems = $event" (change)="onDetectionScrollChange($event)" [parentScroll]="pScrollbar.directiveRef.elementRef.nativeElement">
      <ng-container *ngFor="let item of scroll.viewPortItems">
        <div class="list-item" *ngIf="!(filter || filter.length) || (item.customTitle && item.customTitle.toLowerCase().includes(filter))">
          <div class="list-item-body">
            <h6 class="list-title">{{item.customTitle}}</h6>
            <div class="list-type">{{item.subtitle}}</div>
          </div>
          <div class="list-item-footer">
            <button class="waves-effect waves-light btn-outline" *ngIf="!item.locked && !item.overwrite" (click)="selectDocumentToEdit(item)">Editar</button>
            <button class="waves-effect waves-light btn-outline" *ngIf="!item.locked" (click)="remove(item)">
              {{item.id === removingDocumentId ? 'Eliminant...' : 'Eliminar'}}
            </button>
            <button class="waves-effect waves-light btn-outline" *ngIf="item.state == 'signing'" (click)="selectDocumentToaddSigners(item)">Editar firmants</button>
          </div>
        </div>
      </ng-container>
    </virtual-scroll>
  </perfect-scrollbar>
</div>
