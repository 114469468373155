import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {
  DocPreviewComponent,
  DocumentsService,
  OwnedDocumentsService, PacksService, SubscriptionService,
  UserService
} from 'milcontratos';
import {
  FilledDocument, LegalDocument, PurchasedPack, PurchasedSubscription,
} from 'milcontratos-database';

import {AlertService} from '../shared/services/alert.service';


@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html',
  styleUrls: ['./my-documents.component.scss']
})
export class MyDocumentsComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;
  private routeSubscription: Subscription;

  userName: string = '';

  showTypePage: string = 'draft';
  showDocMenu: boolean = true;
  showSignDocPage: boolean = false;
  showAddSignersPage: boolean = false;

  subscription: PurchasedSubscription;
  packs: PurchasedPack[];

  legalDocument: LegalDocument;
  @ViewChild('preview') preview: DocPreviewComponent;

  docToAddSigns: FilledDocument;
  tabs: number = 0;

  constructor(private activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private docService: DocumentsService,
              private ownerService: OwnedDocumentsService,
              private packsService: PacksService,
              private subscriptionService: SubscriptionService,
              private userService: UserService,
              private router: Router) {}

  async ngOnInit() {
    this.userSubscription = this.userService.getCurrentUserObs().subscribe((res) => {
      if (res) {
        if (res.displayName === null) {
          this.userName = 'Username';
        } else {
          this.userName = res.displayName;
        }
      } else {
        this.userName = '';
      }
    });
    this.routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params['template']) {
        this.showDocPage('template');
      }
    });
    this.packs = (await this.packsService.getAllPurchasedPacks())
      .filter(p => p.remainingDocuments > 0 && p.expirationDate > new Date());
    this.subscription = await this.subscriptionService.getPurchasedSubscription();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  showDocPage(type: string): void {
    this.showTypePage = type;
    this.tabs = 1;
  }

  navigateToBilling(): void {
      this.router.navigateByUrl('mis-compras');
  }

  documentToSign(document) {
    // this.ownerId = document.documentOwnerId;
    // this.signId = document.id;
    // this.filledDocumentId = document.filledDocumentId;
    this.showSignDocPage = true;
  }

  documentToAddSigners(document) {
    this.showDocMenu = false;
    this.showAddSignersPage = true;
    this.getDocument(document.document_id, document.id);
  }


  async getDocument(documentId, filledDocumentId) {
    try {
      const doc = await this.docService.getPermissionToSeeDocument(documentId);
      this.legalDocument = await  this.docService.getCompletDocumentById(documentId);
      this.docToAddSigns = await this.ownerService.getFilledDocument(filledDocumentId);
    } catch (e) {
      await this.alertService.showAlert('Hi ha hagut un error carregant els seus documents.');
    }
    // TODO Preview document
    // this.preview.modifyContent(change.fieldId, value.join(', '));
  }
}
