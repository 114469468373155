import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

import {saveAs} from 'file-saver';
import {DeviceDetectorService} from 'ngx-device-detector';
import {
  DocPreviewComponent,
  DocumentsService,
  OwnedDocumentsService,
  SignatureService,
  FillDocumentService
} from 'milcontratos';
import {FilledDocument, LegalDocument} from 'milcontratos-database';

import {AlertService} from '../../shared/services/alert.service';


@Component({
  selector: 'app-add-signers',
  templateUrl: './add-signers.component.html',
  styleUrls: ['./add-signers.component.scss']
})
export class AddSignersComponent implements OnInit {

  document: LegalDocument;
  filledDocument: FilledDocument;

  loading: boolean = true;
  isResponsive: boolean = false;

  private documentId: string;

  pdfUrl: SafeResourceUrl;
  private pdfUrlPrefix: string = '//drive.google.com/viewerng/viewer?embedded=true&url=';

  tempCheck = {};
  tempLoading = {};

  feedbackDownload: boolean = false;

  renderedDocPreview: boolean = false;
  tabs: number = 0;

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('preview') preview: DocPreviewComponent;


  constructor(private alertService: AlertService,
              private deviceDetectorService: DeviceDetectorService,
              private docService: DocumentsService,
              private fillDocumentService: FillDocumentService,
              private http: HttpClient,
              private ownedDocService: OwnedDocumentsService,
              private ownedServices: OwnedDocumentsService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private signingService: SignatureService) {
  }

  async ngOnInit() {
    this.isResponsive = this.deviceDetectorService.isTablet() || this.deviceDetectorService.isMobile();
    this.documentId = this.route.snapshot.params.document_id;
    await this.getDocument();
    if (this.isResponsive || this.filledDocument.annexes.length > 0 || this.filledDocument.overwriteDocument) {
      this.loadPdf();
    }
  }

  prevPage() {
    this.back.emit(true);
  }

  isSigned(id: string): boolean {
    if (this.filledDocument.signatures) {
      for (let i = 0; i < this.filledDocument.signatures.length; i++) {
        if (this.filledDocument.signatures[i].id === id) {
          return true;
        }
      }
    }
    return false;
  }

  async sendAssignEmail(item) {
    this.tempLoading[item.id] = true;
    try {
      await this.signingService.assignEmailSign(this.documentId, item.id, item.email);
      this.tempCheck[item.id] = true;

    } catch (e) {
      await this.alertService.showAlert();
    }
    delete this.tempLoading[item.id];
  }

  async getDocument() {
    try {
      this.filledDocument = await this.ownedServices.getFilledDocument(this.documentId);
      await this.docService.getPermissionToSeeDocument(this.filledDocument.document_id);
      this.document = await this.docService.getCompletDocumentById(this.filledDocument.document_id);
    } catch (e) {
      await this.alertService.showAlert('Hi ha hagut un error al carregar el seu document.');
    }
  }

  async updateWholeDocument() {
    this.loading = false;
    if (!this.renderedDocPreview) {
      this.renderedDocPreview = true;

      if (this.filledDocument.signatures && this.filledDocument.signatures.length > 0) {
        // add barcode
        for (let i = 0; i < this.filledDocument.signatures.length; i++) {
          this.preview.addSignature('id' + i, this.filledDocument.signatures[i].hash);
        }
      }
      this.fillDocumentService.init(this.document, this.filledDocument, this.preview);
    }
  }

  async onChangeDocumentLanguage(language: string) {
    await this.fillDocumentService.changeLanguage(language);
  }

  async download() {
    try {
      this.feedbackDownload = true;
      const render = await this.ownedDocService.renderInProgressFilledDocuments(this.filledDocument.id);
      const subscriber = this.docService.getUrlResource(render.docxPath).subscribe((url) => {
        if (url) {
          const options = {responseType: 'blob'};
          this.http.get(url, {responseType: 'blob'}).subscribe(
            response => { // download file
              const blob = new Blob([response],
                {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
              const filename = 'document.docx';
              saveAs(blob, filename);
              this.feedbackDownload = false;
            },
            error => {
              this.feedbackDownload = false;
              console.error(`Error: ${error.message}`);
            }
          );
          subscriber.unsubscribe();
        }
      });
    } catch (e) {
      await this.alertService.showAlert();
    }
  }

  async loadPdf() {
    const render = await this.ownedDocService.renderInProgressFilledDocuments(this.filledDocument.id);
    const subscriber = this.docService.getUrlResource(render.pdfPath).subscribe((url) => {
      if (url) {
        this.pdfUrl = url;
        subscriber.unsubscribe();
      }
    });
    this.loading = false;
  }
}
