<div class="mil-modal">
  <div class="tabs">
    <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Signants</div>
    <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Veure document</div>
  </div>

  <div class="modal-box-split">
    <div class="modal-left" [class.tab-active]="tabs === 0">

      <div class="sign-header">
        <div class="back" (click)="prevStep()">
          <i class="material-icons keyboard-arrow-left">keyboard_arrow_left</i>
          Enrrere
        </div>
      </div>

      <div class="sign-complete-container">
        <div class="detail-container">
          <i class="material-icons check">check</i>
          <h5 class="title">Enhorabona, ja has signat el document </h5>
          <div class="text">
            Pots revisar els teus documents signats a la teva àrea d'usuari.
            En cas de no tenir compte, rebràs un e-mail quan tots hagin signat el document.
          </div>
          <button class="btn-white" (click)="prevStep()" >
            <i class="material-icons materialize-alternative-color">remove_red_eye</i>
            Veure els meus documents finalitzats
          </button>
        </div>
      </div>
    </div>
    <div class="modal-right" [class.tab-active]="tabs === 1">
      <div class="modal-right-inner-box">
        <div class="loading" *ngIf="loading">
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
            </div>
          </div>
        </div>
        <mc-doc-preview *ngIf="document && !isResponsive && !filledDocument?.annexes.length && !filledDocument?.overwriteDocument"
                        #preview
                        [document]="document"
                        [downloadable]="false"
                        [loadAnimation]="feedbackDownload"
                        (download)="downloadDoc()"
                        (languageChange)="onChangeDocumentLanguage($event)"
                        (loaded)="updateWholeDocument()"></mc-doc-preview>
        <div *ngIf="(isResponsive || filledDocument?.annexes.length || filledDocument?.overwriteDocument) && pdfUrl"
             class="preview-box-external">
          <pdf-viewer [src]="pdfUrl" [c-maps-url]="'assets/cmaps/'"
                      [render-text]="true"
                      [original-size]="false"
                      [autoresize]="true"
                      style="display: block;">
          </pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</div>
