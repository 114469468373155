<perfect-scrollbar class="search-box" #searchBox>
  <div class="search-items-box" *ngFor="let question of questions; let qi = index;">
    <div class="search-item-title"> {{question.question}} </div>
    <div class="search-item-box">
      <button type="button"
              class="waves-effect waves-dark btn"
              [ngClass]="{'search-item-selected': selectedResponse[qi] === response.value,
            'search-item': selectedResponse[qi] !== response.value}"
              *ngFor="let response of question.responses | keys"
              (click)="optionClicked(response.value, qi)"> {{response.key}} </button>
    </div>
  </div>
</perfect-scrollbar>
<div class="buttons-container">
  <div class="go-back" (click)="resetQuestions()">
    <i class="material-icons">keyboard_arrow_left</i>
    Torna
  </div>
  <span *ngIf="loaded" class="display-lawyer-tree pointer"
           (click)="onChangeToLegalTree();">Arbre Legal</span>
</div>
