<div class="background content-page">
  <div *ngIf="!documents.length" class="no-documents-message">
    Troba el document que desitgis <span [routerLink]="'/search-document'" class="search-document-link">aquí</span>.
  </div>
  <perfect-scrollbar class="document-list" #pScrollbar *ngIf="documents.length > 0">
    <virtual-scroll class="virtual-scroll" #scroll [items]="documents"
                    (update)="scroll.viewPortItems = $event"
                    (change)="onDetectionScrollChange($event)"
                    [parentScroll]="pScrollbar.directiveRef.elementRef.nativeElement">
      <ng-container *ngFor="let item of scroll.viewPortItems">
        <div class="list-item" *ngIf="!(filter || filter.length) || (item.name && item.name.toLowerCase().includes(filter))">
          <div class="list-item-body">
            <h6 class="list-title">{{item.name}}</h6>
            <div class="list-type">{{item.short_description}}</div>
          </div>
          <div class="list-item-footer">
            <div class="list-status"></div>
            <button class="waves-effect waves-light btn-outline-notification" *ngIf="item.free_update_to" (click)="freeUpdate(item)">Actualització gratuïta</button>
            <button class="waves-effect waves-light btn-outline-notification" *ngIf="!item.free_update_to && item.hasLastUpdate" (click)="goToNewVersion(item)">Nova versió</button>
            <button class="waves-effect waves-light btn-outline" (click)="useDocument(item)">Fer servir de plantilla</button>
          </div>
        </div>
      </ng-container>
    </virtual-scroll>
  </perfect-scrollbar>
</div>
