<div class="mil-modal">
    <div class="modal-box-split">
        <div class="modal-left">
            <perfect-scrollbar class="scrollbar-padding">
                <h1 class="title">Entrar al meu compte</h1>
                <div class="sign-in-form">
                    <label class="input-label">Correu electrònic d'associat de PIMEC</label>
                    <div class="sign-in-field input-custom">
                        <input type="text" class="input-text" [(ngModel)]="email"/>
                    </div>
                    <label class="input-label">Contrasenya (CIF de l'Empresa)</label>
                    <div class="sign-in-field input-custom">
                        <input type="password" class="input-password" [(ngModel)]="password"/>
                    </div>
                    <div class="align-center">
                        <button class="waves-effect waves-light btn sign-in-enter" (click)="signIn()">{{signingIn ? 'Entrant...' : 'Entra'}}</button>
                        <div *ngIf="showErrorMsg" class="error-message">Correu o NIF incorrectes.</div>
                    </div>
                    <p>Aquest és un servei exclusiu per a socis. La teva empresa encara no és sòcia de PIMEC?
                        <a href="https://www.pimec.org/ca/institucio/fes-te-soci" target="_BLANK" rel="noreferrer">Fes-te soci!</a></p>
                    <p class="title-suport" >SUPORT LEGAL ONLINE</p>
                    <p>PIMEC LEGALTECH és el teu assistent professional online per a que puguis fer els teus contractes i documents legals.</p>
                    <p><b>Com a soci de PIMEC, GAUDEIX DE DOS USOS L’ANY</b>. Identifica’t com a empresa sòcia, busca el document que necessites
                        i ajuda’t de la guia online per a emplenar-lo, o bé, si no el trobes i tens dubtes, truca’ns!</p>
                    <p>Contacte:</p>
                    <p>Estrella Rincón</p>
                    <p><a href="mailto:erincon@pimec.org" target="_top">erincon@pimec.org</a></p>
                     <p>93 496 45 00</p>
                </div>
            </perfect-scrollbar>
        </div>
        <div class="modal-right sign-image align-center">
        </div>
    </div>
</div>


