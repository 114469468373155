import { Component, OnInit } from '@angular/core';

import {environment} from '../../environments/environment';


@Component({
  selector: 'app-privacity-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacityPolicyComponent implements OnInit {

  webUrl: string = '';

  constructor() { }

  ngOnInit() {
    this.webUrl = environment.webUrl;
  }
}
