import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';


import {Subscription} from 'rxjs';
import {UserService} from 'milcontratos';

import {AlertService} from '../shared/services/alert.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {

    @Output() displaySignIn: EventEmitter<boolean> = new EventEmitter();

    //added by Marc to do delegate login
    private token: string;


    email: string = '';
    password: string = '';
    errorMsg: string = '';

    signingIn: boolean = false;
    showErrorMsg: boolean = false;

    private returnUrl: string;
    private userSubscription: Subscription;

    constructor(private alertService: AlertService,
                private userService: UserService,
                private route: ActivatedRoute,
                private router: Router) { }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.userSubscription = this.userService.getCurrentUserObs().subscribe((res) => {
            if (res) {
                if (this.returnUrl === '/') {
                    this.router.navigateByUrl('home');
                } else {
                    this.router.navigateByUrl(this.returnUrl);
                }
            }
        });

    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    async signIn() {
        try {
            if (!this.signingIn) {
                this.signingIn = true;
                //If(login == 'delegated')
                // call delegated backend function that returns a token and send the token following this example:
                // const token = await [call that returns a token]
                // await this.userService.setExternalToken(token);
                //Endif

                //copied from almudenasc's transition
                const contrasenya = 'ES'.concat(this.password);
                const user = await this.userService.signInPimec(this.token, this.email.trim(), contrasenya);
                console.log("estoy dentro del sign-in-component");
                if (user) {
                    await this.router.navigate(['home']);
                }
            }
        } catch (e) {
            this.signingIn = false;
            console.error(e);
            this.showErrorMsg = true;
        }
    }

}
