import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatStateService {

  private _chatStateOpen: Subject<boolean> = new Subject();

  constructor() { }

  /**
   * Observable that will emit the chat state every time it changes.
   * @returns {Observable<boolean>}
   */
  chatStateOpenObservable(): Observable<boolean> {
    return this._chatStateOpen.asObservable();
  }

  /**
   * Function used to change the chat's state.
   */
  changeState(isOpen: boolean) {
    this._chatStateOpen.next(isOpen);
  }

}
