import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

import {MaterializeAction} from '@samuelberthe/angular2-materialize';
import * as rxp from 'rxp-js';

import {Payer} from './payer';
import {CardType} from 'milcontratos-database';


@Component({
  selector: 'app-card-payment',
  templateUrl: './card-payment.component.html',
  styleUrls: ['./card-payment.component.scss']
})
export class CardPaymentComponent implements OnChanges {

  @Input() payer: Payer;
  @Output() paymentComplete: EventEmitter<boolean> = new EventEmitter();

  modalActions: EventEmitter<string | MaterializeAction> = new EventEmitter();
  errorModalActions: EventEmitter<string | MaterializeAction> = new EventEmitter();
  name: string;
  pan: string;
  expiryYear: string;
  expiryMonth: string;
  cvn: string;
  cardEnum = CardType;
  cardType: CardType = CardType.VISA;

  error: string;
  loading: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if ('payer' in changes) {
      if (this.payer !== undefined) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  }

  async doPayment() {
    this.loading = true;
    try {
      this.validate();
      const expiryYear = parseInt(this.expiryYear);
      const expiryMonth = parseInt(this.expiryMonth);
      await this.payer.doPayment(this.pan, expiryYear, expiryMonth, this.cvn, this.name, this.cardType);
      this.payer = undefined;
      this.paymentComplete.emit(true);
      this.loading = false;
      this.closeModal();
    } catch (e) {
      console.error(e);
      this.loading = false;
      this.displayError(e['errors']);
    }
  }

  validate() {
    const  RealexRemote = rxp.RealexRemote;
    this.assert(!RealexRemote.validateCardNumber(this.pan), 'Numero incorrecto');
    this.assert(!RealexRemote.validateCardHolderName(this.name), 'Nombre incorrecto');
    this.assert(!RealexRemote.validateExpiryDateFormat(`${this.expiryMonth}${this.expiryYear}`), 'Fecha invalida');
    this.assert(!RealexRemote.validateExpiryDateNotInPast(`${this.expiryMonth}${this.expiryYear}`), 'Fecha invalida.');
    if ( this.pan.charAt(0) === '3' ) {
      this.assert(!RealexRemote.validateAmexCvn(this.cvn), 'CVV invalido');
    } else {
      this.assert(!RealexRemote.validateCvn(this.cvn), 'CVV invalido.');
    }
  }

  assert(condition: boolean, message: string) {
    if (condition) {
      throw {
        errors: message
      };
    }
  }

  cancelPayment() {
    this.payer = undefined;
    this.paymentComplete.emit(false);
    this.closeModal();
  }

  private openModal() {
    this.modalActions.emit({action: 'open', params: []});
  }

  private closeModal() {
    this.modalActions.emit({action: 'close', params: []});
  }

  private displayError(error: string) {
    this.error = error;
    this.errorModalActions.emit({action: 'open', params: []});
  }
}
