import {Injectable, OnDestroy} from '@angular/core';

import {ListDocumentsService} from 'milcontratos';
import {Subscription, Subject, Observable} from 'rxjs';
import {PublicLegalDocument, QuestionInterface} from 'milcontratos-database';


@Injectable({
    providedIn: 'root'
})
export class LoadedDocumentsService implements OnDestroy {

    private _documents: Subject<PublicLegalDocument[]> = new Subject();
    private _selectedDocument: Subject<string> = new Subject();
    private _questions: Subject<QuestionInterface[]> = new Subject<QuestionInterface[]>();
    private _selectedResponse: Subject<string[]> = new Subject<string[]>();

    private _listDocumentsSubscription?: Subscription;
    private _directoryId?: string;
    private _questionId?: string;
    private _searchText?: string;
    private _questionsList: QuestionInterface[] = [];
    private _responsesList: string[] = [];

    constructor(private listDocumentsService: ListDocumentsService) { }

    ngOnDestroy() {
        if (this._listDocumentsSubscription) {
            this._listDocumentsSubscription.unsubscribe();
        }
    }

    // Observables

    documentsListObservable(): Observable<PublicLegalDocument[]> {
        return this._documents.asObservable();
    }

    selectedDocumentObservable(): Observable<string> {
        return this._selectedDocument.asObservable();
    }

    questionsObservable(): Observable<QuestionInterface[]> {
        return this._questions.asObservable();
    }

    selectedResponseObservable(): Observable<string[]> {
        return this._selectedResponse.asObservable();
    }

    // Functions to set

    public setSelectedResponse(selectedResponse: string[]) {
        this._responsesList = selectedResponse;
        this._selectedResponse.next(selectedResponse);
    }

    public setQuestions(questions: QuestionInterface[]) {
        this._questionsList = questions;
        this._questions.next(questions);
    }

    public setDocumentsListDirectory(id?: string) {
        this._directoryId = id;
        this.reloadDocuments();
    }

    public setDocumentsListQuestion(id?: string) {
        this._questionId = id;
        this.reloadDocuments();
    }

    public setDocumentsListSearch(text?: string) {
        this._searchText = text;
        this.reloadDocuments();
    }

    public resetDocumentsList() {
        this._questionId = undefined;
        this._directoryId = undefined;
        this._searchText = undefined;
        this.reloadDocuments();
    }

    public setSelectedDocument(id?: string) {
        this._selectedDocument.next(id);
    }

    public reloadDocuments() {
        if (this._listDocumentsSubscription) {
            this._listDocumentsSubscription.unsubscribe();
        }
        this._listDocumentsSubscription = this.listDocumentsService
            .getListDocumentsObs(this._questionId, this._searchText, this._directoryId)
            .subscribe(res => {
                this._documents.next(res);
            });
    }

    public getSearchText(): string {
        return this._searchText;
    }

    public getQuestions(): QuestionInterface[] {
        return this._questionsList;
    }

    public getResponses(): string[] {
        return this._responsesList;
    }
}
