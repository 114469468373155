<div class="mil-modal">
  <div class="modal-box" *ngIf="!loading && (mode === 'resetPasswordPlatform' || mode === 'verifyEmailPlatform')">
    <app-reset-password *ngIf="mode === 'resetPasswordPlatform'"></app-reset-password>
    <app-verify-email *ngIf="mode === 'verifyEmailPlatform'"></app-verify-email>
  </div>
  <div class="modal-box" *ngIf="loading">
    <div class="loading">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-red-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div><div class="gap-patch">
          <div class="circle"></div>
        </div><div class="circle-clipper right">
          <div class="circle"></div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-box" *ngIf="!loading && mode !== 'resetPasswordPlatform' && mode !== 'verifyEmailPlatform'">
    <i  class="material-icons notranslate errorIcon">clear</i>
    <div class="title">Hi ha hagut un error, si us plau, comprova que l'enllaç és correcte.</div>
  </div>
</div>
