<div class="white-box">
  <perfect-scrollbar class="white-layer">
    <h5 class="align-text-center">
      <b>POLÍTICA DE PRIVACITAT</b>
    </h5>
    <p>
        Li preguem que llegeixi atentament aquesta Política de Privacitat, la qual dóna compliment al Reglament (UE)
        2016/679 del Parlament Europeu i del Consell de 27 d'abril de 2016 relatiu a la protecció de les persones
        físiques pel que fa al tractament de dades personals i la lliure circulació d'aquestes dades (Reglament
        General de Protecció de Dades), i per la normativa estatal de desenvolupament del mateix, i supletòriament per la Llei
        Orgànica 15/1999, de 13 de desembre, de protecció de dades de caràcter personal, del Reial Decret 1720/2007, de
        21 de desembre, pel qual s'aprova el Reglament de desenvolupament de la Llei Orgànica de protecció de Dades, i 
        la Llei 34/2002, de 11 de juliol, de serveis de la societat de la informació i de comerç electrònic.
    </p>
    <p>
        Per mitjà d'aquest lloc web titularitat de MILCONTRATOS ONLINE, S.L. es recullen dades de caràcter personal
        necessaris per a la gestió i manteniment dels serveis prestats a través de www.pimeclegaltech.org,
        consistents en la venda de documents relacionats amb l'àmbit del Dret i la prestació de serveis
        jurídics, oferts per advocats especialistes.
    </p>
    <h6><b>RESPONSABLE DEL TRACTAMENT</b></h6>
    <p>
        En qualitat de titular del lloc web, el responsable de tractament és MILCONTRATOS ONLINE, S.L. (En endavant,
        milcontratos.com) amb NIF B-66658147 i Codi LLEI 894500NRXLT1QSW1V505, amb domicili a Plaça Francesc Macià, 7
        Plt. 17, 08029 Barcelona (Espanya), constituïda davant el notari de Barcelona, senyor Juan Ignacio Castro Girona
        Martinez, amb data 2 de desembre de 2015, número de protocol 2.783. Inscrita en el Registre Mercantil de
        Barcelona, Tom 45100, Foli 6122, Full B-476.818, Inscripció 1era.
    </p>
    <p>
      MILCONTRATOS ONLINE, S.L. es compromet a adoptar les mesures de seguretat tècniques, físiques i administratives que
      atorguin una protecció raonable i impedeixin l'accés o ús indegut, la manipulació, deteriorament o pèrdua dels
      dades personals.
    </p>
    <h6><b>FINALITATS DEL TRACTAMENT</b></h6>
    <p>
      Al visitar la pàgina web de www.pimeclegaltech.org, es podran recabar determinades dades:
    </p>
    <ul>
      <li class="circle-empty">Dades identificatives, per exemple nom, cognom, dades de contacte, etc.
      </li>
      <li class="circle-empty">Dades per emplenar els documents adquirits, o facilitats per contractar serveis jurídics, així com per optar per la signatura electrònica.
      </li>
      <li class="circle-empty">Informació comercial.</li>
      <li class="circle-empty">Una altra informació addicional que es recapta quan l'Usuari es posa en contacte amb
          el servei d'atenció al soci o en respondre una enquesta.</li>
    </ul>
    <p>
        S'informa l'Usuari que les dades personals que es facilitin a través dels formularis establerts en
        la pàgina web, seran tractats amb la finalitat de gestionar adequadament la relació establerta amb aquests,
        concretament per a:
    </p>
    <ul>
      <li class="circle-empty">Dur a terme la correcta prestació de serveis, la qual cosa inclou:</li>
      <li>
        <ul>
          <li class="circle-black">Autenticació per a l'accés de l'Usuari al seu compte.</li>
          <li class="circle-black">
              Atendre les comunicacions rebudes a través dels diferents formularis online de la pàgina web, o
              rebudes a través dels mitjans electrònics utilitzats per l'Usuari per a posar-se en contacte amb
            milcontratos.com.
          </li>
          <li class="circle-black">Comunicació amb els Usuaris i/o clients a través dels seus correus electrònics,
              trucades telefòniques o altres mitjans de comunicació equivalents, en relació als serveis contractats
              sempre que sigui raonable i/o necessari per a l'execució (cookies).</li>
          <li class="circle-black">Elaboració dels documents jurídics que l'Usuari desitja adquirir mitjançant la
              web, així com per a gestionar i mantenir els serveis jurídics prestats.</li>
          <li class="circle-black">La informació necessària per a fer efectiva la signatura electrònica a través de la nostra
              pàgina web.</li>
        </ul>
      </li>
      <li class="circle-empty">Gestionar les necessitats de l'empresa, duent a terme l'anàlisi o millora dels
          Serveis i del Lloc web, així com el seu funcionament i les seves eines. Un exemple seria l'anàlisi del
          comportament de l'Usuari i estudi de la manera en la qual usa el lloc web.</li>
      <li class="circle-empty">
          Compliment de les obligacions, de les condicions del Lloc web i dels Serveis oferts, així com de
          tota la legislació aplicable.
      </li>
      <li class="circle-empty">
          Per als interessos legítims de milcontratos.com, tals com fer complir les condicions del Lloc web i dels
          Serveis, dur a terme l'administració de les necessitats empresarials.
      </li>
    </ul>
    <p>Quan l'Usuari el consenti expressament, es podran utilitzar a més, per a:</p>
    <ul>
      <li class="circle-empty">Realitzar comunicacions comercials i màrqueting sobre els productes i serveis
          oferts en milcontratos.com.</li>
      <li class="circle-empty">Usar cookies i altres tecnologies de seguiment per a proporcionar ofertes dels
          serveis de milcontratos.com, en llocs web de tercers.</li>
    </ul>
    <p>L'Usuari pot retirar el seu consentiment en qualsevol moment i sense cost.</p>
    <p>El fet de no facilitar informació obligatòria, pot comportar que no sigui possible la gestió del seu registre
        com a Usuari o l'ús de determinades funcionalitats o serveis disponibles en el lloc web.</p>
    <p>
        Els serveis disponibles a través de www.pimeclegaltech.org, no podran ser utilitzats pels menors d'edat sense
        prèvia autorització dels seus pares, tutors o representants legals, els qui seran els únics responsables dels
        actes realitzats a través del Lloc web pels menors al seu càrrec. En la mesura en què milcontratos.com no té
        capacitat per a conèixer la veritable edat dels Usuaris, s'adverteix que hauran de ser els pares o tutors legals
        els qui habilitin els mecanismes necessaris per a evitar que els menors accedeixin a la pàgina web i facilitin dades
        de caràcter personal sense la seva supervisió, excloent-se de tota responsabilitat a milcontratos.com.
    </p>
    <p>
      milcontratos.com informarà els Usuaris, a través de qualsevol mitjà de comunicació, fins i tot telemàtic, sobre
      els seus productes o serveis, sempre que així ho autoritzi i consenti expressament l'Usuari. Si l'Usuari no
      desitja rebre aquesta informació comercial haurà de mantenir sense marcar la casella corresponent, en el moment d'introduir
      dades personals. A aquest efecte, l'Usuari podrà en tot moment revocar el consentiment prestat
      per a l'enviament de publicitat a través dels mitjans electrònics facilitats, dirigint-se a l'adreça de correu
      electrònic <a href="mailto:info@pimeclegaltech.org" target="_top">info@pimeclegaltech.org</a> o correu postal al
      domicili social situat en Plaza Francesc Macià, 7 planta
      17, 08209 Barcelona, seguint les instruccions que continguin en les comunicacions telemàtiques, o cridant al
      número de telèfon (+ 34) 931 595 454 o (+34) 900 308 106.
    </p>
    <h6>
      <b>DURACIÓ DEL TRATACTAMENT I CONSERVACIÓ DE LOS DADES</b>
    </h6>
    <p>
      milcontratos.com informa els Usuaris que les seves dades seran conservades en un format identificable durant el
      termini necessari per a dur a terme les finalitats per les quals van ser recaptats, per a complir amb
      les obligacions legals i per a les finalitats empresarials. Així mateix, es podran conservar per un termini superior a l'exigit
      en la legislació quan concorri l'interès legítim i empresarial de milcontratos.com, i sempre que no
      estigui prohibit per la Llei.
    </p>
    <p>En cas que l'Usuari tanqués el seu compte o registre, es podran prendre les mesures pertinents per a ocultar les
        dades personals i una altra informació, reservant-se el responsable de tractament la possibilitat de conservació i
        accés a aquestes dades durant el temps necessari per a donar compliment a la legislació corresponent.</p>
    <h6>
      <b>DESTINATARIS DE LES DADES</b>
    </h6>
    <p>
      milcontratos.com podrà compartir les dades personals facilitades per l'Usuari amb altres empreses del grup amb
      la finalitat de prestar els serveis contractats així com amb finalitats administratius interns de l'empresa.
      Així mateix, podrà compartir aquestes dades amb proveïdors de serveis externs que prestin els seus serveis sota la seva
      direcció i pel seu compte. Hi ha la possibilitat que els proveïdors de serveis externs li prestin serveis,
      verifiquin la seva identitat o li prestin assistència tècnica.
    </p>
    <p>
        L'Usuari podrà sol·licitar que des de milcontratos.com s'enviï algun dels documents adquirits a la
        contrapart per a procedir a la signatura d'aquest, per la qual cosa autoritza l'enviament de les dades personals i altra
        informació continguda en aquest document, a la persona que indiqui.
    </p>
    <p>També es podran transmetre les dades personals a tercers, en els següents suposats:
    </p>
    <ul>
      <li class="circle-empty">Per a complir amb la llei, un procediment legal o normativa corresponent.</li>
      <li class="circle-empty">A sol·licitud de les forces de seguretat, organismes públics o uns altres en virtut
          d'una ordre judicial o procés legal.</li>
      <li class="circle-empty">Quan sigui necessari per a evitar danys corporals o pèrdues materials, així com
          per a protegir els interessos vitals d'una persona.</li>
      <li class="circle-empty">Per a dur a terme la recerca de l'incompliment de les condicions d'ús
          o altres condicions legals aplicables als serveis oferts.</li>
      <li class="circle-empty">En cas de compra del negoci de milcontratos.com o part d'ell, o amb empreses amb
          les quals milcontratos.com s'associï o per les quals sigui adquirida en el futur.</li>
      <li class="circle-empty">Per a dur a terme la gestió de regs, detecció i prevenció de frau contra
          milcontratos.com, els seus llocs web o l'ús dels seus Serveis.</li>
      <li class="circle-empty">Per a recolzar les funcions de gestió corporativa, d'auditoria i de compliment
          normatiu.</li>
    </ul>
    <p>
        La informació que milcontratos.com comparteixi amb la plataforma externa s'utilitzarà conforme a la política de
        privacitat d'aquesta.
    </p>
    <p>La web de milcontratos.com està recolzada per una xarxa d'ordinadors, servidors en el núvol i altres
        infraestructures i tecnologia de la informació que inclou proveïdors de serveis externs com FireBase, una
        plataforma de Google.</p>
    <h6>
      <b>DRETS DELS USUARIS</b>
    </h6>
    <p>milcontratos.com es compromet a respectar la confidencialitat de les seves Dades Personals i a garantir-li l'exercici
        dels seus drets. En qualsevol moment, l'Usuari podrà exercir el dret d'accés, rectificació,
        supressió, limitació, oposició i portabilitat de les seves dades personals dirigint-se a MILCONTRATOS ONLINE, S.L.
        en l'adreça de la seva seu social indicada anteriorment o al correu electrònic <a
        href="mailto:info@pimeclegaltech.org" target="_top">info@pimeclegaltech.org</a> :</p>
    <p>Per a l'exercici d'aquests drets, l'Usuari haurà d'acreditar la seva identitat facilitant el seu nom i cognoms,
        fotocòpia del DNI o document equivalent, petició en la qual es concreta la sol·licitud, adreça a l'efecte de
        notificacions, i data i signatura del sol·licitant.</p>
    <p>
        Així mateix, l'Usuari tindrà dret a revocar el consentiment atorgat per al tractament de les seves dades,
        mitjançant l'enviament d'un correu electrònic a <a href="mailto:info@pimeclegaltech.org"
        target="_top">info@pimeclegaltech.org</a> o dirigint-se a la direcció de la seu,
        esmentada anteriorment. En aquest cas, el responsable de tractament tindrà un termini màxim de 10 dies, a comptar
        des de la recepció de la revocació del consentiment, per a cessar en el tractament de les dades personals. Si
        l'Usuari així ho hagués sol·licitat, el responsable de tractament haurà de confirmar-li el cessament en el tractament
        de les seves dades personals.
    </p>
    <p>Finalment, si l'Usuari considera que el tractament de les seves dades personals infringeix la normativa aplicable,
        tindrà dret a presentar una reclamació davant l'autoritat de control corresponent, sense perjudici de dur a terme
        qualsevol altre recurs administratiu o acció judicial pertinent.</p>
    <br>
    <br>
    <p>
        La present Política de Privacitat podrà ser revisada, actualitzada i modificada en qualsevol moment, devent
        MILCONTRATOS ONLINE, S.L. informar els Usuaris a través de l'adreça de correu facilitada en el moment d'emplenar
        el formulari. Així i tot, resulta necessària la revisió de la mateixa per part de l'Usuari de
        forma periòdica o cada vegada que accedeixi al Lloc web amb la finalitat d'estar adequadament informat sobre el tipus de
        dades recopilades i el seu tractament automatitzat.
    </p>
    <div class="extra-bottom"></div>
  </perfect-scrollbar>
</div>
