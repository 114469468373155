<div id="card-modal"
     class="modal" materialize="Modal"
     [materializeParams]="[{dismissable: false}]"
     [materializeActions]="modalActions">
  <div class="modal-content">
    <div class="loading" *ngIf="loading">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-red-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div><div class="gap-patch">
          <div class="circle"></div>
        </div><div class="circle-clipper right">
          <div class="circle"></div>
        </div>
        </div>
      </div>
    </div>
    <div class="card-options-box">
      <div class="card-options">
        <div [ngClass]="{'selected':cardType === cardEnum.VISA}"
             (click)="cardType = cardEnum.VISA">
          <img src="../../assets/cards_logos/VISA.svg" alt="VISA">
        </div>
        <div [ngClass]="{'selected':cardType === cardEnum.MASTERCARD}"
             (click)="cardType = cardEnum.MASTERCARD">
          <img src="../../assets/cards_logos/mc_vrt_pos.svg" alt="Master Card">
        </div>
        <div [ngClass]="{'selected':cardType === cardEnum.AMERICAN_EXPRESS}"
        (click)="cardType = cardEnum.AMERICAN_EXPRESS">
          <img src="../../assets/cards_logos/american-express.svg" alt="American Express">
        </div>
      </div>
    </div>
    <label>
      Número de la tarjeta
      <input type="text" [(ngModel)]="pan"/>
    </label>
    <label>
      Titular de la tarjeta
      <input type="text" [(ngModel)]="name"/>
    </label>
    <div class="input-group">
      <div class="input-group expiration-date-container">
        <label>
          Fecha caducidad
          <input type="number" min="1" max="12" placeholder="Mes" class="month-input" [(ngModel)]="expiryMonth"/>
        </label>
        <span class="expiration-date-separation">/</span>
        <label>
          &nbsp;
          <input type="number" min="18" max="99" placeholder="Año" [(ngModel)]="expiryYear"/>
        </label>
      </div>
      <label class="cvv-input">
        CVV
        <input type="text" [(ngModel)]="cvn"/>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn card-payment-button cancel-button" (click)="cancelPayment()">Cancelar</button>
    <button class="btn card-payment-button pay-button" (click)="doPayment()">Pagar</button>
  </div>
</div>

<div id="errorModal" class="modal errorModal" materialize="Modal"
     [materializeParams]="[{dismissible: false}]"
     [materializeActions]="errorModalActions">

  <div class="modal-content">
    <h4>{{error}}</h4>
  </div>
  <div class="modal-footer">
    <a class="modal-action modal-close waves-effect waves-light btn">Cerrar</a>
  </div>
</div>
