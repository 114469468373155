import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {UserService} from 'milcontratos';

import {AlertService} from '../../shared/services/alert.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  password: string = '';
  rePassword: string = '';
  showInvalidPasswordError: boolean = false;

  loading: boolean = true;
  tokenError: boolean = false;
  private token: string;

  private routeSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private alertService: AlertService,
              private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this. routeSubscription = this.activatedRoute.queryParams.subscribe(async params => {
      this.token = params['oobCode'];
      try {
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.tokenError = true;
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  validator(): boolean {
    return this.password === this.rePassword && (this.password !== '' && this.rePassword !== '');
  }

  async onChangePassword() {
    try {
      await this.userService.completeResetPasswordPlatform(this.token, this.password);
      await this.alertService.showAlert('Contrasenya canviada correctament!', 'success', 'Ok', 'Èxit!');
      this.router.navigate(['/sign-in']);
    } catch (e) {
      console.error(e);
      if (e.code === 'auth/invalid-password' || e.code === 'auth/weak-password') {
        this.showInvalidPasswordError = true;
      } else {
        await this.alertService.showAlert('Ha succeït un error, si us plau, comprova que l\'enllaç és correcte i torna a provar-ho.');
      }
    }
  }
}
