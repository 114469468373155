import { Component, OnInit } from '@angular/core';

import {environment} from '../../environments/environment';


@Component({
  selector: 'app-legal-warning',
  templateUrl: './legal-warning.component.html',
  styleUrls: ['./legal-warning.component.scss']
})
export class LegalWarningComponent implements OnInit {

  webUrl: string = '';

  constructor() { }

  ngOnInit() {
    this.webUrl = environment.webUrl;
  }
}
