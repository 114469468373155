


                   <!--//(sendDoneImmediately)="sendToDoneImmediately()" //this was in case documents were "free"-->

<mcs-fill-document *ngIf="document && filledDocument"
                   [document]="document"
                   [receiveDownloadDocument]="feedbackDownload"
                   [filledDocument]="filledDocument"
                   [canBeSigned]="true"
                   [hideBuyReview]="true"
                   [hideReviewStep]="true"
                   [sendingToReview]="sendingToReview"
                   (sendDone)="sendToDone()"
                   (sendDoneImmediately)="completeDocumentImmediately()"
                   (changedTitle)="sendTitle($event)"
                   (updateValues)="updateValues($event)"
                   (updateTranslatedValues)="updateTranslatedValues($event)"
                   (sendReview)="sendToReview($event)"
                   (downloadDocument)="download()"
                   (buyReviews)="onBuyReviews()"></mcs-fill-document>

<div *ngIf="showNotificationPopup" class="notification-popup-overlay">
    <div class="notification-popup">
      <h4>
        Pots sortir del document en qualsevol moment, la teva informació quedarà emmagatzemada.
        <br>
        <br>
        Per a continuar omplint el document ves al menú <span>Els meus documents</span> i el trobaràs a la secció <span>Documents en curs</span>.
      </h4>
      <h6>
        <label for="doNotShow">
          <input type="checkbox" id="doNotShow"
                 [(ngModel)]="showNotificationStored"/>
          <span>No tornar a mostrar</span>
        </label>
      </h6>
      <div class="accept-button-box">
        <button class="btn" (click)="acceptNotificationPopup()">Acceptar</button>
      </div>
    </div>
  </div>
  
  <div class="loading" *ngIf="!document || !filledDocument" >
    <div class="preloader-wrapper big active">
      <div class="spinner-layer spinner-red-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div><div class="gap-patch">
        <div class="circle"></div>
      </div><div class="circle-clipper right">
        <div class="circle"></div>
      </div>
      </div>
    </div>
  </div>
