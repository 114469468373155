import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ListDocumentsService} from 'milcontratos';
import {Subscription} from 'rxjs';
import {DirectoryInterface, PublicLegalDocument} from 'milcontratos-database';

import {LoadedDocumentsService} from '../loaded-documents.service';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';


@Component({
    selector: 'app-lawyer-tree',
    templateUrl: './lawyer-tree.component.html',
    styleUrls: ['./lawyer-tree.component.scss']
})
export class LawyerTreeComponent implements OnInit, OnDestroy {

    @Output() loaded: EventEmitter<void> = new EventEmitter();
    @Output() changeToExpertSystem: EventEmitter<void> = new EventEmitter();
    directoryTree: DirectoryInterface;
    documents: PublicLegalDocument[] = [];

    listDocumentsSubscription: Subscription;

    constructor(private listDocumentsService: ListDocumentsService,
                private documentsService: LoadedDocumentsService,
                private router: Router) {
    }

    async ngOnInit() {
        const currentUrl = this.router.url.split('/');
        const lastUrlPart = currentUrl[currentUrl.length - 1];

        this.directoryTree = await this.listDocumentsService.getDirectory();
        if (lastUrlPart !== 'lawyer-tree') {
            this.documentsService.setDocumentsListDirectory(lastUrlPart);
        } else {
            this.documentsService.setDocumentsListDirectory(undefined);
        }
        this.listDocumentsSubscription = this.documentsService.documentsListObservable().subscribe(res => {
            this.documents = res;
        });
        await this.documentsService.documentsListObservable().pipe(first()).toPromise();
        this.loaded.emit();
    }

    ngOnDestroy() {
        if (this.listDocumentsSubscription) {
            this.listDocumentsSubscription.unsubscribe();
        }
    }

    onChangeToExpertSystem() {
        this.changeToExpertSystem.emit();
    }
}
