import {
    Component, OnInit, ViewChild,
} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

import {DocPreviewComponent, DocumentPurchaseState, DocumentsService, Languages, OwnedDocumentsService} from 'milcontratos';
import {FileFormat, FileType, PublicLegalDocument} from 'milcontratos-database';
import { IConverterOptionsChangeable } from 'ngx-showdown';
import {saveAs} from 'file-saver';

import {LoadedDocumentsService} from '../search-doc/loaded-documents.service';
import {AlertService} from '../shared/services/alert.service';
import {PreviousRouteService} from '../previous-route.service';
import {Subscription} from 'rxjs/Rx';


//   delete later:
import {DocumentAdministrationService} from 'milcontratos';
import { getDocumentPlatformPrice, getAllDocumentPlatformPrice } from 'milcontratos-database';
import {environment} from '../../environments/environment';


@Component({
    selector: 'app-document-info',
    templateUrl: './document-info.component.html',
    styleUrls: ['./document-info.component.scss']
})
export class DocumentInfoComponent implements OnInit {

    languages = Languages;

    document: PublicLegalDocument;
    mainLanguageDocument: PublicLegalDocument;
    documentTextId: string;
    documentLanguage: string;
    documentPreviewUrl = '';

    downloading = false;
    documentStates = DocumentPurchaseState;
    state: DocumentPurchaseState;

    //delete later:
    precito: Promise<number | undefined>;
    //platformprices: Promise<object | undefined>;
platformprices: Promise<number | undefined>;

    //If(documents == 'payment')
    price: number;
    priceIva: number;
    private iva: number;
    //Endif

    routeSubscription: Subscription;



    tabs = 0;

    //If(documents == 'free')
    blockBuyButton = false;
    //Endif

    options: IConverterOptionsChangeable = {'headerLevelStart': 3};

    @ViewChild('preview') preview: DocPreviewComponent;

    constructor(private activatedRoute: ActivatedRoute,
                private alertService: AlertService,
                private documentsService: DocumentsService,
                private documentsAdministrationService: DocumentAdministrationService,
                private http: HttpClient,
                private loadedDocumentsService: LoadedDocumentsService,
                private ownedDocumentService: OwnedDocumentsService,
                private router: Router,
                private location: Location,
                private previousRouteService: PreviousRouteService) {
    }

    async ngOnInit() {
        try {
            this.routeSubscription = this.activatedRoute.params.subscribe(async (params) => {
                this.documentTextId = params['id'];
                this.documentLanguage = params['language'];
                const currentUrl = this.location.path();
                //console.log('eston son los parámetros que se piden: ' + this.documentTextId);
                this.mainLanguageDocument = await this.documentsService.getPublicDocumentBytextId(this.documentTextId);
                // if the selected language is not the main language
                if (this.documentLanguage && this.documentLanguage !== this.mainLanguageDocument.documentLanguage) {
                    this.document = await this.documentsService
                        .getPublicDocumentTranslationById(this.mainLanguageDocument.availableLanguages[this.documentLanguage]);
                } else {
                    this.document = this.mainLanguageDocument;
                    this.documentLanguage = this.document.documentLanguage;
                }
                if (this.document) {
                    //this.price = await getDocumentPlatformPrice('5f7d1f2f-dc41-4892-9234-9629096dde48', this.document.version_id);
                    //await.this.document.getDocumentPlatform getDocumentPlatformPrice(platformId: string, versionId: string): Promise<number> {
                    //await getDocumentPricePlatform();
                    /*const result = await getDocumentPlatformPrice('5f7d1f2f-dc41-4892-9234-9629096dde48', this.document.version_id);
                    console.log('este es el precio correcto?' + result);
                    console.log('this.document.version_id:' + this.document.version_id);
                    const deberia = await this.documentsAdministrationService
                        .getDocumentPricePlatform('5f7d1f2f-dc41-4892-9234-9629096dde48', this.document.version_id);
                    console.log('esto es el precio que debería dar: ' + deberia);
                        console.log(this.document);*/
                    //@if(documents == 'payment')
                    const iva = await this.documentsService.getIVA();
                    //   this.price = this.document.price;
                    console.log(environment.platformId);
                    this.price = await this.documentsAdministrationService
                        .getDocumentPricePlatform('5f7d1f2f-dc41-4892-9234-9629096dde48', this.document.version_id);
                    this.priceIva = this.price + this.price * (iva / 100);
                    //@endif
                    try {
                        this.documentPreviewUrl = await this.documentsService
                            .getImagePreviewUrl(this.document.textId, this.documentLanguage).toPromise();
                    } catch (e) {
                        console.log(e);
                    }
                    this.state = await this.documentsService.getDocumentPurchaseState(this.mainLanguageDocument.id);
                } else {
                    this.router.navigate(['/search-document']);
                }
            });
        } catch (e) {
            console.error(e);
            await this.alertService.showAlert();
        }
    }

    unselectDocument() {
        this.loadedDocumentsService.setSelectedDocument(undefined);
        // const previousUrl = this.previousRouteService.getPreviousUrl();
        // const currentUrl = this.router.url;
        // if (previousUrl === currentUrl) {
        this.router.navigate(['/search-document']);
        // } else {
        //     this.location.back();
        // }
    }

    async redirectFillDocument() {
        const id = await this.ownedDocumentService.newFilledDocument(this.mainLanguageDocument.id);
        this.router.navigate(['/fill-document', id]);
    }

    async buyDocument() {
        try {
            if (!this.blockBuyButton) {
                this.blockBuyButton = true;
                const documentAcquisitionExceeded = await this.documentsService
                    .hasUserExceedMaxDocumentPromotion(); //abans era Acquisition en comptes de promotion
                console.log(documentAcquisitionExceeded);
                if (documentAcquisitionExceeded === true) {
                    //await this.alertService.showAlert('Ja has exhaurit els documents gratuïts de la promoció.');
                    this.router.navigate(['/buy-document', this.mainLanguageDocument.id]);
                } else {
                    const id = await this.ownedDocumentService.tryToBuyDocumentAndFill(this.document.id);
                    this.router.navigate(['/fill-document', id]);
                    //await this.alertService.showAlert('Aquest document és gratuït degut a la promoció vigent.', 'success', 'Ok', 'Éxito');
                }
                this.blockBuyButton = false;
            }
        } catch (e) {
            console.log(e);
            await this.alertService.showAlert();
            this.blockBuyButton = false;
        }
    }

    //If(documents == 'payment')
    /*async redirectToBuy() {
        this.router.navigate(['/buy-document', this.mainLanguageDocument.id]);
    }*/
    //endIf

    async startDownloadStaticDocuments() {
        if (this.document && this.document.isStaticDocument) {
            const doc = await this.documentsService.getCompletStaticDocumentById(this.mainLanguageDocument.id);
            const path = doc.document[this.documentLanguage];
            let name = doc.name;
            let contentType;

            if (path.endsWith(FileFormat.docx)) {
                name = `${name}.${FileFormat.docx}`;
                contentType = FileType.docx;
            } else if (path.endsWith(FileFormat.pptx)) {
                name = `${name}.${FileFormat.pptx}`;
                contentType = FileType.pptx;
            } else if (path.endsWith(FileFormat.pdf)) {
                name = `${name}.${FileFormat.pdf}`;
                contentType = FileType.pdf;
            }
            await this.download(path, name, contentType);
        }
    }

    private async download(path, filename, contentType) {
        try {
            this.downloading = true;
            const subscriber = this.documentsService.getUrlResource(path).subscribe((url) => {
                if (url) {
                    const options = {responseType: 'blob'};
                    this.http.get(url, {responseType: 'blob'}).subscribe(
                        response => { // download file
                            const blob = new Blob([response],
                                {type: contentType});
                            saveAs(blob, filename);
                            this.downloading = false;
                        },
                        error => {
                            this.downloading = false;
                            console.error(`Error: ${error.message}`);
                        }
                    );
                    subscriber.unsubscribe();
                }
            });
        } catch (e) {
            console.error(e);
            this.downloading = false;
            await this.alertService.showAlert('Hi ha hagut un error al descarregar el document.');
        }
    }
}
