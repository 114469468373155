<div class="mil-modal">
  <div class="modal-box-vertical-split">
    <div class="modal-top">
      <div class="container main-container">
        <i class="material-icons notranslate prefix purchase-check-icon">check</i>
        <h3 class="purchase-congratulations-text">Enhorabona, has adquirit el document.</h3>
        <div class="purchase-text">Pots veure els teus documents comprats a la teva àrea d'usuari.</div>
        <div class="purchase-buttons-container">
          <div class="purchase-button" (click)="redirectToMyDocuments()">
            <i class="material-icons notranslate prefix purchase-eye-icon">remove_red_eye</i>
            Veure els meus documents comprats
          </div>
          <div class="purchase-button" (click)="redirectFillDocument()">
            <i class="material-icons notranslate prefix purchase-edit-icon">edit</i>
            Emplenar document
          </div>
          <div class="purchase-button" (click)="redirectMyPurchases()">
            <i class="material-icons notranslate prefix purchase-edit-icon">assignment</i>
            Sol·licitar factura
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
