<div class="white-box">
  <perfect-scrollbar class="white-layer">
    <h5 class="text-center">
      <b>AVIS LEGAL Y CONDICIONS D'US</b>
    </h5>
    <p></p>
    <p class="text-center"><b>milcontratos.com</b></p>
    <p class="text-center">Plaça Francesc Macià, 7 Plt. 17, 08029 Barcelona (Espanya)</p>
    <p class="text-center">NIF: B-66658147 / Codi LLEI: 894500NRXLT1QSW1V505</p>
    <p>
        El present document té per objecte establir les condicions generals d'ús
        del portal www.pimeclegaltech.org (d'ara endavant, el web), ja sigui amb el seu actual
        denominació o amb qualsevol altra que pogués figurar en el futur.
    </p>
    <p>
        L'accés a aquest Lloc web i / o aplicació. Així com l'ús dels materials
        continguts en la mateixa, implica que l'usuari ha llegit, coneix i accepta sense reserva
        alguna les presents condicions, havent d'accedir periòdicament a les mateixes per al seu
        coneixement, ja que les mateixes poden ser modificades unilateralment per part del
        titular de la web.
    </p>
    <p>
        En compliment del deure d'informació recollit en l'article 10 de la Llei
        34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i del Comerç
        Electrònic (LSSI-CE), s'informa a l'Usuari que el titular del a web és
        MILCONTRATOS ONLINE, S.L. (En endavant, milcontratos.com), amb NIF B-66.658.147
        i Codi LLEI 894500NRXLT1QSW1V505, amb domicili a Plaça Francesc Macià, 7
        Plt. 17, 08029 Barcelona (Espanya), constituïda davant el notari de Barcelona, Joan
        Ignacio Castro Girona Martinez, amb data 2 de desembre de 2015, nombre de
        protocol 2.783. Inscrita en el Registre Mercantil de Barcelona, Tom 45100, Foli
        6122, Full B-476.818, Inscripció 1era.
    </p>
    <p>
      MILCONTRATOS ONLINE, S.L. es compromet a respectar la privacitat dels
      Usuaris i s'esforça per oferir-los una experiència de client segura.
    <p>
        El titular del web es reserva el dret a modificar les presents condicions amb
        objecte de adecuaras a la legislació vigent aplicable en cada moment, a les
        novetats jurisprudencials i les pràctiques habituals de mercat.
    </p>
    <p>
        Les presents condicions d'ús no exclouen la possibilitat que Milcontratos.com
        pugui establir condicions particulars per a l'ús de determinats continguts i
        serveis, que hauran de ser coneguts i acceptats per l'Usuari amb caràcter previ a
        l' utilització / contractació dels mateixos.
    </p>
    <h6><b>USUARIS</b></h6>
    <p>
        Es considera Usuari tota persona, ja sigui física o jurídica, que accedeixi, navegui,
        utilitzi o participi en els serveis i activitats, gratuïtes o oneroses, desenvolupades
        mitjançant el present lloc Web.
    </p>
    <h6><b>CONDICIONS D'ÚS</b></h6>
    <p>
        El simple accés i ús al lloc web implica que l'usuari consent quedar vinculat al
        present Avís Legal i Condicions d'Ús, la Política de Cookies i al a Política de
        Privacitat, els quals constitueixen un acord entre l'Usuari i MILCONTRATOS
        ONLINE, S.L. i substitueixen qualsevol altre pacte, acord o promesa anterior convinguda
        entre les parts. Aquest accés i ús, serà sota exclusiu risc i responsabilitat del
        
        Usuari, en cas de no estar d'acord amb les mateixes, es prega no accedeixi i / o
        utilitzeu la present pàgina web.
    </p>
    <p>
        L'accés al contingut de la web és gratuït, sense necessitat de subscripció o registre
        previ, llevat que l'Usuari vulgui adquirir algun dels documents o contractar
        algun dels serveis oferts a la web de milcontratos.com.
    </p>
    <p>
      MILCONTRATOS ONLINE, S.L. es reserva el dret a denegar o retirar l'accés al
      portal i / o els serveis oferts, sense necessitat de preavís a aquells usuaris que
      poguessin estar incomplint les presents condicions d'ús.
    </p>
    <p>
        Per fer ús dels serveis de www.pimeclegaltech.org els menors d'edat han de
        haver obtingut l'autorització dels seus pares, tutors o representants legals,
        que seran responsables de tots els actes realitzats a través del web pels
        menors al seu càrrec. En aquells serveis en què expressament s'assenyali, l'
        accés es permetrà únicament i exclusivament a majors de 18 anys.
    </p>
    <p>
        L'Usuari es compromet a fer un ús adequat i lícit de la pàgina web i de
        els continguts que figuren en la mateixa, de conformitat amb la legislació aplicable en
        cada moment, les presents condicions, la bona fe, les normes de convivència, la
        moral, els bons costums generalment acceptats i l'ordre públic. Així, el
        Usuari s'obliga i compromet a no fer ús d'aquest lloc web amb fins
        il·lícits, lesius dels drets i interessos de tercers, d'altres Usuaris, o de
        qualsevol altre Usuari d'Internet, o de qualsevol forma que pugui danyar, inutilitzar,
        sobrecarregar, deteriorar o impedir la normal utilització dels continguts.
    </p>
    <p>
        Queda totalment prohibit l'ús indegut de la present pàgina web mitjançant la
        introducció intencionada de programes o materials tecnològics perjudicials o
        nocius per a la mateixa (per exemple, de virus o troians). No es permet l'accés no
        autoritzat al servidor on es trobi allotjada o relacionada informació sobre la
        pàgina web. De la mateixa manera, queda totalment prohibit realitzar atacs de
        denegació de serveis o de servei distribuït.
    </p>
    <p>
      MILCONTRATOS ONLINE, S.L. no garanteix que la seva pàgina web compleixi amb les
      legislacions d'altres països, ja sigui total o parcialment. Si l'Usuari resideix o es
      troba domiciliat en qualsevol altre lloc que no sigui Espanya, i decideix accedir i / o
      navegar en el present lloc web, ho farà sota la seva pròpia responsabilitat i risc,
      havent d'assegurar que tal accés i / o navegació compleix amb la legislació local
      aplicable en el seu cas.
    </p>
    <h6><b>
        DISPONIBILITAT I CONTINUÏTAT DEL PORTAL</b></h6>
    <p>
        El portal www.pimeclegaltech.org és de caràcter indefinit, en el que a la seva visualització
        a internet es refereix. No obstant això no garanteix la disponibilitat, accés i continuïtat
        del funcionament del portal i dels seus serveis, i es reserva el dret a efectuar les
        modificacions que consideri oportunes sense necessitat de previ avís, podent
        suprimir o afegir tant els continguts i serveis que es prestin a través de la mateixa,
        com la forma en què aquests apareguin presentats o localitzats.
    </p>
    <p>
      MILCONTRATOS ONLINE, S.L. posa tots els mitjans perquè el contingut i
      informació subministrades mitjançant la present web compleixi amb els estàndards de
      qualitat, exactitud, veracitat, fiabilitat, utilitat i actualitat. No obstant això, no es
      garanteix el complet compliment d'aquests estàndards en tot moment. el
      Usuari haurà de tenir en compte que aquest material pogués no reflectir l'estat més
      recent sobre les qüestions presentades.
    </p>
    <h6><b>CONTINGUTS Y SERVEIS</b></h6>
    <p>
        El contingut del web o aplicació de Milcontratos.com té la finalitat d'oferir al
        Usuari documents relacionats amb el món del Dret i formularis usats
        habitualment en actuacions davant de l'administració pública. Així mateix, s'ofereixen
        serveis d'assessorament jurídic prestat per advocats especialistes.
    </p>
    <p>
        En el cas que l'Usuari així ho sol·liciti expressament, milcontratos.com podrà
        designar un assessor llegar especialitzat per contactar-amb la finalitat de resoldre
        qualsevol qüestió respecte als serveis oferts mitjançant la pàgina web, o que
        excedeixi els mateixos. S'informa a l'Usuari que aquesta gestió pot suposar costos
        addicionals, quan es tracti de consultes jurídiques, els quals seran comunicats
        prèviament i subjectes al consentiment exprés de l'Usuari.
    </p>
    <p>
        El titular del portal respon únicament i exclusivament dels serveis que presta i dels continguts directament originats pel mateix. Aquesta responsabilitat quedarà
        exclosa en els casos en què en què concorrin causes de força major o en els
        supòsits en què la configuració dels equips de l'Usuari no sigui l'adequada per
        permetre el correcte ús dels serveis prestats per milcontratos.com.
    </p>
    <p>
        El mer accés a la informació continguda en el present Lloc web no vincula de
        cap manera a l'Usuari, de manera que no li genera cap tipus de compromís
        contractual. L'accés a aquesta informació només té caràcter consultiu i / o
        publicitari.
    </p>
    <p class="font-weight-bold">Continguts y Serveis de Tercers</p>
    <p>
        El titular del portal no controla prèviament, aprova ni fa propis els continguts,
        serveis, opinions, comunicacions de dades, arxius, productes i qualsevol classe
        d'informació de tercers, persones jurídiques o físiques, recollits al portal i que
        no hagin estat introduïts per milcontratos.com.
    </p>
    <p>
        Així mateix, es podran incloure en la pàgina web mitjans d'enllaç, com links, banners,
        botons o altres similars, a llocs de tercers, respecte dels quals no garanteix la
        licitud, fiabilitat, utilitat, veracitat i actualitat dels continguts, informacions i
        serveis prestats per aquests.
    </p>
    <p>
        El titular del portal no controla amb caràcter previ i no garanteix l'absència de virus i
        altres elements en els continguts i serveis prestats per tercers a través del portal
        que puguin introduir alteracions en el sistema informàtic, documents electrònics
        o fitxers dels Usuaris.
    </p>
    <p>
        En el moment en què l'Usuari accedeix als enllaços de tercers i abandona el
        lloc web, el present Avís Legal i Condicions d'Ús, així com la Política de
        Privadesa i la Política de Cookies deixaran de tenir efecte, i són aplicables les seves
        pròpies condicions i polítiques.
    </p>
    <p>
        El titular del portal no serà responsable, ni indirecta ni subsidiàriament, dels danys i
        perjudicis de qualsevol naturalesa derivats de l'utilització i contractació dels
        continguts i dels serveis de tercers als quals es pugui accedir des
        www.pimeclegaltech.org. Amb caràcter enunciatiu, i en cap cas limitatiu, no serà
        responsable pels danys i perjudicis de qualsevol naturalesa derivats de:
    </p>
    <ol type="a">
      <li>la infracció dels drets propietat intel·lectual i industrial i el compliment
          defectuós o incompliment dels compromisos contractuals adquirits per
          tercers;</li>
      <li>la realizació d'actes de competència deslleial i publicitat il·lícita;</li>
      <li>la inadequació i defraudació de les expectatives dels Serveis i
           Continguts dels tercers;</li>
      <li>els vicis i defectes de tota classe dels Serveis i continguts de tercers
           prestats a través del Portal</li>
    </ol>
    <h6><b>NOTIFICACIONS</b></h6>
    <p>
        L'Usuari podrà comunicar-se amb MILCONTRATOS ONLINE, S.L., ja sigui mitjançant
        correu electrònic a info@milcontratos.com, mitjançant la utilització dels formularis
        disponibles a la pàgina web, de forma presencial a l'adreça indicada a l'inici del
        present document o a través dels números de telèfon (+ 34) 931 595 454 o (+34)
      900 308 106.
    </p>
    <p>
        Totes les notificacions i comunicacions realitzades per qualsevol mitjà es
        consideraran eficaços amb caràcter general.
    </p>
    <p>
        Mitjançant l'ús d'aquesta pàgina web, l'Usuari accepta que la major part d'aquestes
        comunicacions amb milcontratos.com siguin per escrit i per via electrònica.
        milcontratos.com es posarà en contacte amb l'Usuari a través de les dades de
        contacte facilitades a través del formulari o es facilitarà informació penjant avisos
        en el lloc web.
    </p>
    <h6><b>PROTECCIÓ DE DADES DE CARÀCTER PERSONAL</b></h6>
    <p>
        Abans de completar el Registre d'Usuari o compliment del Formulari de contacte,
        l'Usuari haurà de llegir i acceptar la Política de Privacitat, referent a la normativa de
        protecció de dades i la Llei de Serveis de la Societat de la Informació.
    </p>
    <p>
        La informació o dades personals que faciliti l'Usuari, seran tractades d'acord amb l'
        establert en la Política de Privadesa i la legislació vigent sobre aquesta matèria.
    </p>
    <p>
        L'Usuari autoritza de manera expressa el tractament de les seves dades personals, per
        les finalitats determinades en la Política de Privadesa, marcant la casella
        corresponent en el moment d'emplenar el formulari de recollida de dades,
        que serà obligatori per procedir a l'enviament d'aquest.
    </p>
    <p>
        Les dades de caràcter personal proporcionades per part de l'Usuari mitjançant el
        present lloc web formaran part del sistema de tractament de dades (fitxers i
        bases de dades) titularitat de MILCONTRATOS ONLINE, S.L. Aquesta informació
        tindrà caràcter confidencial i secret, no podent ser revelada a tercers aliens, sense
        causa legalment justificada o autorització expressa de l'Usuari en qüestió.
    </p>
    <p>
        L'Usuari es compromet a facilitar als MILCONTRATOS ONLINE, S.L. les seves dades de
        contacte de forma veraç i exacta (correu electrònic i altres dades de contacte
        necessaris), així com a no proporcionar informació o documentació falsa,
        fraudulenta o il·lícita.
    </p>
    <p>
        L'Usuari té dret a l'accés, oposició, rectificació, cancel·lació, revocació,
        limitació i portabilitat de les seves dades personals a través de l'adreça de correu
        electrònic info@milcontratos.com o per correu ordinari a l'adreça indicada
        anteriorment.
    </p>
    <p>
        Per a més informació pot consultar la Política de Privacitat de milcontratos.com.
    </p>
    <h6><b>POLÍTICA DE COOKIES</b></h6>
    <p>
        Des de la web www.pimeclegaltech.org es podran utilitzar dispositius de
         emmagatzematge i recuperació de dades (cookies) en equips i terminals dels
         destinataris, a condició que els mateixos hagin donat el seu consentiment després
         que se'ls hagi facilitat informació clara i completa sobre la seva utilització.
    </p>
    <p>
        Per a més informació pot consultar la Política de Cookies de milcontratos.com.
    </p>
    <h6><b>PROPIETAT INTEL·LECTUAL I INDUSTRIAL</b></h6>
    <p>
        Tots els continguts d'aquesta pàgina web (textos, fotografies, gràfics, imatges,
         icones, tecnologia, programari, enllaços i altres continguts), així com el seu disseny gràfic
         i codis font són titularitat de MILCONTRATOS ONLINE, S.L., sense que puguin
         entendre cedits a l'Usuari cap dels drets d'explotació sobre els
         mateixos més enllà de l'estrictament necessari per al correcte ús de la Pàgina web,
         i amb independència que siguin susceptibles o no de propietat intel·lectual.
    </p>
    <p>
        En el supòsit d'existir en el present lloc web contingut amb titularitat de tercers,
        MILCONTRATOS ONLINE, S.L. manifesta tenir els permisos, autoritzacions i
        llicències pertinents que permetin al titular i Usuaris de la web fer ús dels
        mateixos.
    </p>
    <p>
        Es prohibeix l'ús dels continguts de pàgina web per promocionar, contractar o
        divulgar publicitat o informació pròpia o de terceres persones sense l'autorització de
        milcontratos.com, ni remetre publicitat o informació valent-se dels
        serveis o informacions que es posen a disposició dels usuaris,
        independentment de si l' utilització és gratuïta o no.
    </p>
    <p>
        Els Usuaris no faran cap ús dels continguts de la web de forma contrària a la
        llei, usos i costums i ordre públic, ni copiaran, distribuiran, difondran,
        transformaran, modificaran o manipularan els mateixos.
    </p>
    <p>
        L'accés a www.pimeclegaltech.org no implica en cap cas l'autorització o llicència
        per part de MILCONTRATOS ONLINE, S.L. per a l'utilització, més enllà de l'ús
        estrictament personal, de cap dels continguts i / o informació del mateix, ni
        sobre cap dret relacionat amb ells.
    </p>
    <h6><b>LIMITACIÓ DE LA RESPONSABILITAT</b></h6>
    <p>
        L'usuari d'aquesta pàgina web hi accedeix pel seu propi compte i risc.
        MILCONTRATOS ONLINE S.L., no es fa responsable dels errors o bé omissions
        que pogués contenir la web, si bé posa tots els mitjans al seu abast per
        prevenir-los.
    </p>
    <p>
        L'Usuari reconeix i accepta que l'accés i ús del lloc web i / o dels serveis
       inclosos en el mateix té lloc lliurement i conscient, sota la seva exclusiva
       responsabilitat. MILCONTRATOS ONLINE, S.L. no serà en cap cas responsable
       de l'ús que l'usuari pogués fer de la web o dels serveis, ni dels danys i
       perjudicis que poguessin derivar-se del mateix.
    </p>
    <p>
        A causa de la naturalesa oberta d'aquesta pàgina web i a la possibilitat que es
        produeixin errors en l'emmagatzematge i transmissió d'informació digital, no
        garantim la precisió i seguretat de la informació transmesa o bé obtinguda per
        mitjà d'aquesta pàgina web llevat que s'estableixi expressament el contrari en la
        mateixa.
    </p>
    <p>
      MILCONTRATOS, S.L.no serà responsable de cap dany o pèrdua que es derivi
      d'un atac de denegació de servei, virus o qualsevol altre programa o material
      tecnològicament perjudicial o nociu que pugui afectar al seu ordinador, equip
      informàtic, dades o materials com a conseqüència de l'ús d'aquesta pàgina web o de la
      descàrrega de continguts de la mateixa o als quals la mateixa redireccioni. tampoc
      assumeix cap responsabilitat pels continguts dels enllaços de tercers
      establerts en el seu lloc web, ni pels danys i perjudicis que poguessin produir-se per
      l'accés, ús o il·licitud dels continguts, comunicacions, opinions, productes i
      serveis dels llocs web no gestionats pel titular d'aquesta web, declarant que en
      cap cas procedirà a examinar o exercitar cap control sobre el contingut d'
      aquests.
    </p>
    <p>
        Així mateix, no assumirà cap responsabilitat per l'incompliment o retard en el
        compliment d'alguna de les obligacions assumides, quan el mateix es degui a
        qualsevol esdeveniment, acte, falta d'exercici, omissió o accident que estigui fora
        del nostre control raonable(&quot;Causa de Força Major&quot;).
    </p>
    <h6><b>ACCIONS LEGALS</b></h6>
    <p>
        MILCONTRATOS ONLINE, S.L. es reserva la facultat d'exercitar les accions civils
        o penals que consideri oportunes per l' utilització indeguda d'aquest lloc web i els seus
        continguts, o per l'incompliment de les presents estipulacions.
    </p>
    <h6><b>LLEI APLICABLE I JURISDICCIÓ</b></h6>
    <p>
        Totes les qüestions relatives al portal www.milcontratos.com es regeixen per les lleis
        espanyoles i se sotmeten a la jurisdicció dels Jutjats i Tribunals de Barcelona.
    </p>
    <p>
        Així mateix, milcontratos.com figura com a establiment adherit al Sistema Arbitral
        de consum mitjançant el qual els consumidors de milcontratos.com podran resoldre
        qualsevol tipus de reclamació de forma extrajudicial.
    </p>


  </perfect-scrollbar>
</div>
