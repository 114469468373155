<div class="mil-modal" *ngIf="showEmailVerification">
    <div class="modal-box" *ngIf="!emailIsSent">
        <h4 class="title">Debes verificar tu dirección de correo.</h4>
        <h4 class="title">¿No has recibido ningún correo al registrarte?</h4>
        <button class="waves-effect waves-light btn-outline" (click)="onSendVerificationEmail()">
            {{sendingEmail ? 'Enviando...' : 'Enviar correo'}}
        </button>
    </div>
    <div class="modal-box" *ngIf="emailIsSent">
        <i  class="material-icons notranslate successIcon">check</i>
        <h4 class="title">Se ha enviado el correo de verificación correctamente.</h4>
    </div>
</div>
