<div class="mil-modal" *ngIf="displayDoc">
  <div class="tabs">
    <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Finalitzat</div>
    <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Veure document</div>
  </div>

  <div class="modal-box-split">
    <div class="modal-left" [class.tab-active]="tabs === 0">

      <div class="sign-complete-container">
        <div class="detail-container">
          <i class="material-icons check">check</i>
          <div class="title">Document: {{filledDocument.customTitle}}</div>
          <div class="text">Ja pots descarregar els teus documents.</div>
          <div class="download-buttons-box">
            <button class="btn-white" (click)="downloadDocPdf()" >
              <i class="material-icons material-alternative-color">file_download</i>{{PDFStatus}}
            </button>
            <button class="btn-white" *ngIf="legalDocument.num_signatures !== 0 && !filledDocument?.overwriteDocument && !filledDocument?.annexes.length" (click)="downloadDocDOCX()" >
              <i class="material-icons material-alternative-color">file_download</i>{{DOCXStatus}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-right" [class.tab-active]="tabs === 1">
      <div class="modal-right-inner-box" *ngIf="legalDocument">
        <div class="loading" *ngIf="loading">
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
            </div>
          </div>
        </div>
        <mc-doc-preview *ngIf="legalDocument && !isResponsive && !filledDocument?.annexes.length && !filledDocument?.overwriteDocument"
                        #preview
                        [document]="legalDocument"
                        [downloadable]="legalDocument.num_signatures !== 0"
                        [loadAnimation]="feedbackDownload"
                        (download)="downloadDocDOCX()"
                        (languageChange)="onChangeDocumentLanguage($event)"
                        (loaded)="updateWholeDocument()"></mc-doc-preview>
        <div *ngIf="(isResponsive || filledDocument?.annexes.length || filledDocument?.overwriteDocument) && pdfUrl"
             class="preview-box-external">
          <pdf-viewer [src]="pdfUrl" [c-maps-url]="'assets/cmaps/'"
                      [render-text]="true"
                      [original-size]="false"
                      [autoresize]="true"
                      style="display: block;">
          </pdf-viewer>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="loading" *ngIf="!displayDoc">
  <div class="preloader-wrapper big active">
    <div class="spinner-layer spinner-red-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div><div class="gap-patch">
      <div class="circle"></div>
    </div><div class="circle-clipper right">
      <div class="circle"></div>
    </div>
    </div>
  </div>
</div>
