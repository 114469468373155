import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {PublicLegalDocument, QuestionInterface} from 'milcontratos-database';
import {Subscription} from 'rxjs';
import {DocumentsService, Languages} from 'milcontratos';

import {ChatStateService} from '../../shared/services/chat/chat-state.service';
import {LoadedDocumentsService} from '../loaded-documents.service';


@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit, OnDestroy {

    languages = Languages;

    documents: PublicLegalDocument[] = [];
    loadingDisplay: boolean = true;

    searchText: string = undefined;
    questions: QuestionInterface[] = [];
    selectedResponse: string[] = [];

    private listDocumentsSubscription: Subscription;
    private questionsSubscription: Subscription;
    private selectedResponseSubscription: Subscription;
    private selectedComponentSubscription: Subscription;

    constructor(private chatState: ChatStateService,
                private router: Router,
                private loadedDocumentsService: LoadedDocumentsService,
                private documentsService: DocumentsService) {}

    async ngOnInit() {
        this.loadingDisplay = true;
        this.questions = this.loadedDocumentsService.getQuestions();
        this.selectedResponse = this.loadedDocumentsService.getResponses();
        this.loadedDocumentsService.reloadDocuments();
        this.listDocumentsSubscription = this.loadedDocumentsService.documentsListObservable().subscribe(res => {
            this.documents = res;
            this.loadingDisplay = false;
        });
        this.questionsSubscription = this.loadedDocumentsService.questionsObservable().subscribe(res => {
            this.questions = res;
        });
        this.selectedResponseSubscription = this.loadedDocumentsService.selectedResponseObservable().subscribe(res => {
            this.selectedResponse = res;
        });
        this.searchText = this.loadedDocumentsService.getSearchText();
    }

    ngOnDestroy() {
        if (this.listDocumentsSubscription) {
            this.listDocumentsSubscription.unsubscribe();
        }
        if (this.selectedComponentSubscription) {
            this.selectedComponentSubscription.unsubscribe();
        }
        if (this.questionsSubscription) {
            this.questionsSubscription.unsubscribe();
        }
        if (this.selectedResponseSubscription) {
            this.selectedResponseSubscription.unsubscribe();
        }
    }

    searchDoc(value) {
        this.documents = [];
        let messageToSend;
        if (value === '') {
            this.searchText = undefined;
        } else {
            messageToSend = this.searchText.trim();
        }
        this.loadingDisplay = true;
        if (this.listDocumentsSubscription) {
            this.listDocumentsSubscription.unsubscribe();
        }
        this.loadedDocumentsService.setDocumentsListSearch(messageToSend);
        this.listDocumentsSubscription = this.loadedDocumentsService.documentsListObservable().subscribe(res => {
            this.documents = res;
            this.loadingDisplay = false;
        });
    }

    displayChat() {
        this.chatState.changeState(true);
    }

    async documentSelected(id: string, event: Event) {
        // event.preventDefault();
        this.loadedDocumentsService.setSelectedDocument(id);
    }

    resetSearch() {
        this.searchText = '';
        this.loadedDocumentsService.setQuestions([]);
        this.loadedDocumentsService.setSelectedResponse([]);
        this.loadedDocumentsService.resetDocumentsList();
    }

    optionClicked(index: number) {
        this.selectedResponse = this.selectedResponse.slice(0, index);
        this.questions = this.questions.slice(0, index + 1);
        this.loadedDocumentsService.setQuestions(this.questions);
        this.loadedDocumentsService.setSelectedResponse(this.selectedResponse);
        this.loadedDocumentsService.setDocumentsListQuestion(this.selectedResponse[index - 1]);
    }
}
