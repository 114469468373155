<div class="mil-modal">
  <div class="tabs">
    <div class="tab" [class.active]="tabs == 0" (click)="tabs = 0">Dades</div>
    <div class="tab" [class.active]="tabs == 1" (click)="tabs = 1">Compres</div>
  </div>
  <div class="modal-box-split">
    <div class="modal-left" [class.tab-active]="tabs == 0">
      <h2 class="title">Dades de facturació</h2>
      <div class="loaded-spinner" *ngIf="!invoiceDetails">
        <div class="loading">
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <perfect-scrollbar *ngIf="invoiceDetails">
        <h6>Per a poder generar la teva factura només has d'omplir el següent formulari. Nosaltres guardarem les teves dades
          per a futures visites. Un cop omplert el formulari, pots generar tantes factures com necessitis.
        </h6>
        <div class="form-container">
          <div class="input-container">
            <label class="input-label">Nom</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.name"/>
            <div *ngIf="errors.name" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">Cognoms</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.surname"/>
            <div *ngIf="errors.surname" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">Email</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.email"/>
            <div *ngIf="errors.email" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
            <div *ngIf="errors.emailFormat" class="warning">L'email ha de tenir un format vàlid.</div>
          </div>
          <div class="input-container">
            <label class="input-label">Telèfon</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.phone"/>
            <div *ngIf="errors.phone" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">Adreça</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.address"/>
            <div *ngIf="errors.address" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">Ciutat</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.city"/>
            <div *ngIf="errors.city" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">Codi postal</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.postalCode"/>
            <div *ngIf="errors.postalCode" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">Província</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.province"/>
            <div *ngIf="errors.province" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">País</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.country"/>
            <div *ngIf="errors.country" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="checkboxes-box input-container">
            <label for="is-company" class="checkbox">
              <input id="is-company" class="with-gap styled-checkbox" type="checkbox"
                     [(ngModel)]="invoiceDetails.isCompany"/>
              <span>¿La factura és a nom d'una empresa?</span>
            </label>
          </div>
          <div *ngIf="invoiceDetails.isCompany" class="input-container">
            <label class="input-label">Raó Social</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.companyName"/>
            <div *ngIf="errors.companyName" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="input-container">
            <label class="input-label">{{invoiceDetails.isCompany ? 'CIF' : 'DNI, NIE o passaport'}}</label>
            <input type="text" class="input-text" [(ngModel)]="invoiceDetails.identification"/>
            <div *ngIf="errors.identification" class="warning">Per a generar la factura ha d'omplir aquest camp.</div>
          </div>
          <div class="buttons-container">
            <button type="button" class="waves-effect waves-light btn-outline-blue" (click)="onSaveInvoiceDetails()">
              {{savingInvoiceDetails ? 'Desant...' : 'Desa'}}
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="modal-right purchases-container" [class.tab-active]="tabs == 1">
      <h2 class="title">Les meves compres</h2>
      <div class="loaded-spinner" *ngIf="loadingTransactions">
        <div class="loading">
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <perfect-scrollbar>
        <div *ngFor="let transaction of transactions" class="purchase-item">{{transaction.date | date: 'dd/MM/yyyy HH:mm'}}
          <button *ngIf="!transaction.hasInvoice" class="btn-outline-blue" (click)="downloadInvoice(transaction, true)">
            {{downloadingId === transaction.id ? 'Baixant...' : 'Baixar Tiquet'}}
          </button>
          <button *ngIf="!transaction.hasInvoice" class="btn-outline-blue" [disabled]="generatingId === transaction.id" (click)="generateInvoice(transaction)">
            {{generatingId === transaction.id ? 'Generant...' : 'Generar factura'}}
          </button>
          <button *ngIf="transaction.hasInvoice" class="btn-outline-blue" (click)="downloadInvoice(transaction)">
            {{downloadingId === transaction.id ? 'Baixant...' : 'Baixar factura'}}
          </button>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
