import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {

  showCookiesBanner: boolean = true;

  constructor(private router: Router) { }

  ngOnInit() {
    const mCookies = localStorage.getItem('milcontratos-cookies-accepted');
    this.showCookiesBanner = mCookies === null;
  }

  acceptCookies() {
    localStorage.setItem('milcontratos-cookies-accepted', 'true');
    this.showCookiesBanner = false;
  }

  showMoreInfo() {
    this.router.navigate(['/cookies']);
  }
}
