import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import {ListDocumentsService} from 'milcontratos';
import {IconNode} from 'milcontratos-database';
import {ChatStateService} from '../shared/services/chat/chat-state.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  iconsList: IconNode[];
  urlsList: string[] = [];
  tabs: number = 0;

  constructor(private listDocumentsService: ListDocumentsService,
              private chatState: ChatStateService) { }

  async ngOnInit() {
    this.iconsList = await this.listDocumentsService.getIcons();
    for (const icon of this.iconsList) {
      const newUrl = this.getUrlDirection([].concat(icon.directoryIds));
      this.urlsList.push(newUrl.join('/'));
    }
  }

  getUrlDirection(array) {
    array.shift();
    array.unshift('/lawyer-tree');
    return array;
  }

  displayChat() {
    this.chatState.changeState(true);
  }

}
