import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {User} from 'firebase';
import {UserService} from 'milcontratos';
import {Subscription} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate, OnDestroy {
  private userSubscription: Subscription;
  user: User | null;

  constructor(private router: Router,
              private userService: UserService) {
    this.userService.getCurrentUserObs().subscribe(async (user) => {
      if (this.user && user === null) {
          this.router.navigate(['/sign-in']);
      }
      this.user = user;
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!!this.user && !this.user.isAnonymous) {
      return true;
    }

    this.router.navigate(['transition'], { queryParams: { returnUrl: state.url }});
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
