<div class="mil-modal">
  <div class="tabs">
    <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Benvingut</div>
    <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Categories</div>
  </div>
  <div class="modal-box-split">
    <div class="modal-left" [class.tab-active]="tabs === 0">
      <div class="modal-left-buttons-box">
        <div>
          <h2 class="title">Benvingut</h2>
          <div class="my-doc-name">
            <p>Benvingut a PIMEC Legaltech, el teu assistent professional online per a que puguis fer els teus contractes i documents legals.</p>

            <p>Pots buscar el document que necessites directament
              <a class="link" [routerLink]="['/search-document']">aquí</a>
              , o clicant en alguna de les
              <span class="text-display-chat link" (click)="tabs = 1">categories</span>.</p>

            <p>Una vegada hagis seleccionat el document, prem el botó “Emplenar” i podràs personalitzar-lo afegint les
                dades necessàries. Tots els documents romandran guardats a la secció
                <a class="link" [routerLink]="['/my-documents']">Els meus documents</a>
              , per a que en tinguis accés en un futur.</p>

            <p>Si tens qualsevol dubte, posa't en contacte amb nosaltres a través del
              <span class="text-display-chat link" (click)="displayChat()">xat</span>,
              enviant-nos un <a class="link" [routerLink]="['/support']">mail</a>, o trucant-nos al
                departament jurídic de PIMEC al 93 496 45 00. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-right" [class.tab-active]="tabs === 1">
      <div class="background content-page">
        <h2 class="title">Documents legals per categories</h2>
        <perfect-scrollbar class="document-list">
          <div class="particular-feed">
            <a *ngFor="let icon of iconsList; let i = index;"
               class="particular-icons"
               [routerLink]="urlsList[i]">
              <i class="material-icons">{{icon.iconName}}</i>
              <span class="icon-name">{{icon.name}}</span>
            </a>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>
