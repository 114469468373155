<div class="white-box">
  <perfect-scrollbar class="white-layer">
    <h5 class="text-center">
      <b>POLÍTICA DE COOKIES</b>
    </h5>
    <p>
      A MILCONTRATOS, S.L. utilitzem cookies pròpies i de tercers amb la finalitat de
      millorar la qualitat del nostre servei, proporcionar una millor experiència de
      navegació, e identificar els problemes tècnics que puguin sorgir a la web.
      Així mateix, en cas que l'usuari presti el seu consentiment, milcontratos.com
      podrà utilitzar cookies que li permetin obtenir més informació sobre preferències i
      personalitzar la pàgina web amb els seus interessos individuals.
    </p>
    <p>
      En compliment amb el que disposa l'article 22.2 de la Llei 34/2002, de 11 de juliol,
      de serveis de la societat de la informació i del comerç electrònic, aquesta pàgina
      web l'informa sobre la política de cookies.
    </p>
    <h6>
      <b>Què són les cookies?</b>
    </h6>
    <p>
      Les cookies són petits fitxers de dades que es descarreguen a l'ordinador i
      altres dispositius de comunicacions, que emmagatzemen informació i es guarden en el
      navegador de l'Usuari. Les cookies permeten a una pàgina o lloc web emmagatzemar i
      recuperar informació sobre els hàbits de navegació, permetent recordar
      diferents paràmetres e informació sobre l'Usuari.
    </p>
    <p>
      L'Usuari podrà en tot moment modificar les preferències del navegador utilitzat
      amb la finalitat d'impedir l'instal·lació d'aquests fitxers a l'hora de consultar aquesta
      web.
    </p>
    <h6>
      <b>Quin tipus de cookies utilitza aquest lloc web?</b>
    </h6>
    <p>
      El lloc web de www.pimeclegaltech.org pot utilitzar serveis de tercers que recopilen
      informació amb fins estadístics, per d'ús del lloc web per part de l'Usuari i per la
      prestació d'altres serveis relacionats amb el mateix i altres serveis d'internet.
    </p>
    <p>
      En particular, aquest lloc web utilitza Google Analytics, un servei analític de web
      prestat per Google, Inc. amb domicili a Estats Units amb seu central a 1600
      Amphitheatre Parkway, Mountain View, Califòrnia 94043. Per la prestació d'aquests
      serveis, s'utilitzen cookies que recopilen informació, inclosa l'adreça IP
      de l'Usuari, que serà transmesa, tractada i emmagatzemada per Google en els termes
      fixats en la web Google.com. Incloent la possible transmissió d'aquesta informació
      a tercers per raons d'exigència legal o quan aquests tercers processin la informació per compte de Google.
    </p>
    <p>
      El present lloc web utilitza les cookies següents:
    </p>
    <p>
      <b>Cookies tècniques:</b> Són aquelles que permeten a l'Usuari la navegació a través d'
            una pàgina web, plataforma o aplicació i l'utilització de les diferents opcions o
            serveis que en ella hi hagi com per exemple: controlar el trànsit i la comunicació
            de dades, identificar la sessió, accedir a parts d'accés restringit, realitzar el
            procés de compra d'una comanda, realitzar la sol·licitud d'inscripció o participació en
            un event, utilitzar elements de seguretat durant la navegació, emmagatzemar
            continguts per a la difusió de vídeos o so, o compartir contingut a través de
            xarxes socials.
    </p>
    <p>
      <b>Cookies de personalització:</b> Són aquelles que permeten a l'Usuari la navegació a través de
            una pàgina web, plataforma o aplicació i l utilització de les diferents opcions o
            serveis que en ella hi hagi com per exemple: controlar el trànsit i la comunicació
            de dades, identificar la sessió, accedir a parts d'accés restringit, realitzar el
            procés de compra d'una comanda, realitzar la sol·licitud d'inscripció o participació en
            un event, utilitzar elements de seguretat durant la navegació, emmagatzemar
            continguts per a la difusió de vídeos o so, o compartir contingut a través de
            xarxes socials.
    </p>
    <p>
      <b>Cookies d'anàlisis:</b> Són aquelles que, o bé tractades per milcontratos.com o bé per
            tercers, permeten quantificar el nombre d'usuaris i així realitzar el mesurament i
            anàlisi estadístic de l' utilització que fan els Usuaris del servei ofert. per
            això s'analitza la seva navegació a la nostra pàgina web per tal de millorar l'oferta de
            productes o serveis que li oferim.
    </p>
    <p>
      <b>Cookies publicitàries:</b>
      Són aquelles que, o bé tractades per milcontratos.com o bé per
      tercers, permeten gestionar de la forma més eficaç possible l'oferta dels espais
      publicitaris que hi ha a la pàgina web, adequant el contingut de l'anunci al
      contingut del servei sol·licitat o l'ús que realitzi de la nostra pàgina web. per a això
      podem analitzar els seus hàbits de navegació a internet i podem mostrar
      publicitat relacionada amb el seu perfil de navegació.
    </p>
    <p>
      <b>Cookies de publicitat comportamental:</b> Són aquelles que permeten la gestió, de la
            manera més eficaç possible, dels espais publicitaris que, si escau, l'editor hagi
            inclòs en una pàgina web, aplicació o plataforma des de la qual presta el servei
            sol·licitat. Aquestes galetes emmagatzemen informació del comportament dels Usuaris
            obtinguda a través de l'observació continuada dels seus hàbits de navegació, el que
            permet desenvolupar un perfil específic per a mostrar publicitat en funció del mateix.
    </p>
    <p><b>Gestió de las cookies</b></p>
    <p>
        L'usuari pot permetre, bloquejar o eliminar les cookies instal·lades en el seu equip
         mitjançant la configuració de les opcions del navegador en el vostre ordinador.
         No obstant això, si ho fa, és possible que la pàgina web no funcioni correctament i que
         milcontratos.com no pugui oferir-li una experiència d'usuari òptima.
    </p>
    <h6><b>Google Chrome:</b></h6>
    <ol>
      <li>Cliquem en el icona de menú.</li>
      <li>Seleccionem "configuració".</li>
      <li>Seleccionem "configuració avançada".</li>
      <li>Seleccionem "privacitat".</li>
      <li>Seleccionem "configuració de contingut".</li>
      <li>Seleccionem la función que desitgi:</li>
      <ol>
        <li>Permetre que s'emmagatzemin dades locals.</li>
        <li>Conservar dades locals només fins que surtis del navegador.</li>
        <li>No permetre que se guardin dades dels llocs.</li>
        <li>Bloquejar les dades de llocs i cookies de tercers.</li>
      </ol>
      <li>Un cop seleccionis la opció desitjada, clicar a "fet".</li>
    </ol>
    <h6><b>Internet Explorer:</b></h6>
    <ol>
      <li>Cliquem en la icono de "inici".</li>
      <li> Seleccionar "opcions d'internet" al menú "eines". </li>
      <li> Seleccionar "privacitat". </li>
      <li> Seleccionar "configuració". </li>
      <li> Seleccionar l'opció que desitgi i clicar a "acceptar". </li>
    </ol>
    <h6><b>Microsoft Edge (Explorer 10):</b></h6>
    <ol>
        <li> Cliquem a la icona "més". </li>
       <li> Seleccionar "configuració". </li>
       <li> Seleccionar "configuració avançada". </li>
       <li> Seleccionar "cookies" dins de "Privadesa i serveis". </li>
       <li> Seleccionar l'opció que desitgi al menú desplegable. </li>
    </ol>
    <h6><b>Firefox:</b></h6>
    <ol>
        <li> Cliquem a la icona "menú". </li>
          <li> Seleccionar "opcions". </li>
          <li> Seleccionar "privacitat". </li>
          <li> Seleccionar l'opció que desitgi al menú desplegable. </li>
    </ol>
    <h6><b>Safari:</b></h6>
    <ol>
        <li> Cliquem a "safari". </li>
        <li> Seleccionar "preferències". </li>
        <li> Seleccionar "privacitat". </li>
        <li> Seleccionar "galetes i dades de llocs web". </li>
        <li> Seleccionar l'opció que desitgi entre les disponibles. </li>
    </ol>
    <p>
        Ha de tenir en compte que si l'usuari elimina o bloqueja totes les galetes d'aquest
              lloc web, és possible que part d'aquest no funcioni correctament o la qualitat de la
              pàgina web pugui veure afectada.
    </p>
    <p>
        Si necessita més informació sobre la nostra Política de Cookies, pot contactar
              amb Milcontratos.com a través dels canals de comunicació. Així mateix se li
              recomana que consulti les pàgines web de suport de cada navegador.
    </p>
    <div class="extra-bottom"></div>
  </perfect-scrollbar>
</div>
