import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';
import {Observable, Subscription} from 'rxjs';
import {User} from 'firebase';
import {ChatService, UserService} from 'milcontratos';

import {ChatStateService} from '../shared/services/chat/chat-state.service';
import {AlertService} from '../shared/services/alert.service';
import {AssignedInterface, GroupMessage} from 'milcontratos-database';


@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

    public userSubscription: Subscription;
    private chatSubscription: Subscription;
    private assignedUserSubscription: Subscription;
    private chatStateSubscription: Subscription;
    private writingSubscription: Subscription;

    groupMessages: GroupMessage[];
    cursor: any;
    textareaEmpty: string = '';
    userAdmin: AssignedInterface | undefined;

    openChat: boolean = false;
    noSupportAvailable: boolean = false;
    adminIsWriting: boolean = false;

    userObservable: Observable<User | null>;

    @ViewChild('chatBox') chatBox: PerfectScrollbarComponent;

    constructor(private alertService: AlertService,
                private chat: ChatService,
                private chatState: ChatStateService,
                private user: UserService) { }

    ngOnInit() {
        this.groupMessages = [];

        this.chatStateSubscription = this.chatState.chatStateOpenObservable().subscribe(res => {
            this.openChat = res;
        });

        this.userObservable = this.user.getCurrentUserObs();

        this.userSubscription = this.user.getCurrentUserObs().subscribe( user => {
            // this.userConnected = res;
            if (user) {
                this.chatSubscription = this.chat.chatObservable().subscribe((res) => {
                    this.groupMessages.push(res);
                    if (!res.isFromClient) {
                        const audio = new Audio('../../assets/new_chat.mp3');
                        audio.play();
                    }
                    if (this.openChat) {
                        setTimeout(() => {
                            this.chatBox.directiveRef.scrollToBottom(0, 300);
                        }, 0);
                    }
                    if (!this.cursor) {
                        this.cursor = res.timestampStart.getTime() / 1000.0;
                    }
                });
                this.loadOldMessages(20);
                this.assignedUserSubscription = this.chat.assignedUserObservable().subscribe(async (result) => {
                    const res = await result;
                    this.noSupportAvailable = !res;
                    this.userAdmin = res;
                });

                this.writingSubscription = this.chat.getAdminTypingObservable().subscribe(
                    (msg: any) => {
                        this.adminIsWriting = msg.payload.node_.value_;
                    }
                );
            } else {
                this.groupMessages = [];
                if (this.chatSubscription) {
                    this.chatSubscription.unsubscribe();
                }
                if (this.assignedUserSubscription) {
                    this.assignedUserSubscription.unsubscribe();
                }
                if (this.writingSubscription) {
                    this.writingSubscription.unsubscribe();
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.chatSubscription) {
            this.chatSubscription.unsubscribe();
        }
        if (this.assignedUserSubscription) {
            this.assignedUserSubscription.unsubscribe();
        }
        if (this.chatStateSubscription) {
            this.chatStateSubscription.unsubscribe();
        }
        if (this.writingSubscription) {
            this.writingSubscription.unsubscribe();
        }
    }

    async setIsUserTyping(isTyping: boolean) {
        if (this.textareaEmpty.length) {
            await this.chat.setIsClientTyping(isTyping);
        } else {
            // if the user erased all the textarea content
            await this.chat.setIsClientTyping(false);
        }
    }

    async loadOldMessages(num: number) {
        try {
            const res = await this.chat.getPaginateChat(num);
            this.groupMessages = this.groupMessages.concat(res.messages);
            this.groupMessages.pop();
            this.cursor = res.cursor;
        } catch (e) {
            console.error(e);
            await this.alertService.showAlert('Hi ha hagut un error carregant els missatges');
        }
    }

    async sendMessage(value) {
        await this.setIsUserTyping(false);
        try {
            value = value.trim();
            if (value !== '') {
                this.textareaEmpty = '';
                await this.chat.sendMessage(value);
            }
        } catch (e) {
            console.error(e);
            if (e === 'No support available') {
                this.noSupportAvailable = true;
                setTimeout(() => {
                    this.chatBox.directiveRef.scrollToBottom(0, 300);
                }, 0);
            } else if (!e.toString().startsWith('Error: permission_denied')) {
                await this.alertService.showAlert();
            }
        }
    }

    openChatWindow() {
        this.openChat = true;
        setTimeout(() => {
            this.chatBox.directiveRef.scrollToBottom(0, 300);
        }, 0);
    }

    empty(value) {
        this.textareaEmpty = value;
    }
}
