import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {UserService} from 'milcontratos';
import {first} from 'rxjs/internal/operators';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {

  loading: boolean = true;
  tokenError: boolean = false;
  private token: string;

  private routeSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this.routeSubscription = this.activatedRoute.queryParams.subscribe(async params => {
      this.token = params['oobCode'];
      try {
        let user = this.userService.getCurrentUser();
        if (!user) {
          user = await this.userService.getCurrentUserObs().pipe(first()).toPromise();
        }
        if (!user.emailVerified) {
          await this.userService.completeEmailVerificationPlatform(this.token);
          location.reload(true);
        }
        this.loading = false;
      } catch (e) {
        console.error(e);
        this.tokenError = true;
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  async redirectToSearchDocuments() {
    await this.router.navigate(['/search-document']);
  }

  async redirectToMyDocuments() {
    await this.router.navigate(['/my-documents']);
  }

  async redirectToLogin(e) {
    e.preventDefault();
    await this.router.navigate(['/sign-in']);
  }
}
