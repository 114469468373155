import { Injectable } from '@angular/core';
import {QuestionInterface} from 'milcontratos-database';


@Injectable({
  providedIn: 'root'
})
export class SearchDocumentService {

  private questions: QuestionInterface[] = [];
  private selectedOptions: string[] = [];
  private lastSelectedOption: string = '';
  private lastIndex: number;
  private comesFromBack: boolean = false;

  constructor() {}

  updateOptions(selectedOptions: string[], lastSelectedOption: string, questions: QuestionInterface[], lastIndex: number) {
    this.selectedOptions = selectedOptions;
    this.lastSelectedOption = lastSelectedOption;
    this.questions = questions;
    this.lastIndex = lastIndex;
  }

  getOptions() {
    return {
      selectedOptions: this.selectedOptions,
      lastSelectedOption: this.lastSelectedOption,
      questions: this.questions,
      lastIndex: this.lastIndex
    };
  }

  setComesFromBack(comesFromBack: boolean) {
    this.comesFromBack = comesFromBack;
  }

  getComesFromBack() {
    return this.comesFromBack;
  }
}
