<div class="form-container" *ngIf="!loading && !tokenError">
  <div class="title">Introduzca su nueva contraseña:</div>
  <div class="sign-in-field">
    <label class="input-label">Contraseña</label>
    <input type="password" class="input-password" (keyup)="password = $event.target.value" (change)="validator()"/>
    <div *ngIf="showInvalidPasswordError" class="warning">Contraseña no válida. Debe tener un mínimo de 6 carácteres.</div>
  </div>
  <div class="sign-in-field">
    <label class="input-label">Repetir Contraseña</label>
    <input type="password" class="input-password" (keyup)="rePassword = $event.target.value" (change)="validator()"/>
    <div class="warning" *ngIf="password !== rePassword">La contraseña no coincide.</div>
  </div>
  <div class="button-container">
    <button class="btn" [class.disabled]="!validator()" (click)="onChangePassword()">Cambiar contraseña</button>
  </div>
</div>
<div class="center-align" *ngIf="!loading && tokenError">
  <i  class="material-icons notranslate errorIcon">clear</i>
  <div class="title">
    Por tu seguridad el enlace ha caducado. Para restaurar tu contraseña haz click
    <a [href]="'/sign-in?resetPassword=true'">aquí</a>
    y pulsa la opción ¿Has olvidado tu contraseña?
  </div>
</div>
