// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  paypalMode: 'sandbox',
  production: false,
  platformId: '5f7d1f2f-dc41-4892-9234-9629096dde48',
  firebase: {
    apiKey: 'AIzaSyDAeNiMrl55FV-Z4MpCNKdUEh70lj1iBIs',
    authDomain: 'milcontratos-dev-209711.firebaseapp.com',
    databaseURL: 'https://milcontratos-dev-209711.firebaseio.com/',
    storageBucket: 'milcontratos-dev-209711.appspot.com',
    messagingSenderId: '632391846328',
  },
  urlFunctions: 'https://us-central1-milcontratos-dev-209711.cloudfunctions.net',
  bucket: 'milcontratos-dev-209711.appspot.com',
  webUrl: 'https://dev.pimeclegaltech.org',
    baseUrl: 'https://pipe-pimeclegaltech-dev.firebaseapp.com',
    redsysUrl: 'https://sis-t.redsys.es:25443/sis/realizarPago'

};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
 import 'zone.js/dist/zone-error';  // Included with Angular CLI.
