import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {ListDocumentsService} from 'milcontratos';
import {DirectoryInterface} from 'milcontratos-database';

import {LoadedDocumentsService} from '../../loaded-documents.service';
import {environment} from '../../../../environments/environment';


@Component({
    selector: 'app-lawyer-branches',
    templateUrl: './lawyer-branches.component.html',
    styleUrls: ['./lawyer-branches.component.scss']
})
export class LawyerBranchesComponent implements OnInit {

    @Input() directoryId: string;

    directoryHrefs: string[] = [];
    directoryTree: DirectoryInterface;
    selectedDirectory: number = undefined;

    constructor(private listDocumentsService: ListDocumentsService,
                private lawyerTreeService: LoadedDocumentsService,
                private location: Location) {
    }

    async ngOnInit() {
        const path = this.location.path().split('/');
        this.directoryTree = await this.listDocumentsService.getDirectory(this.directoryId);
        // gets the hrefs
        if (this.directoryTree.directories) {
            for (const dir of Object.values(this.directoryTree.directories)) {
                this.directoryHrefs.push(await this.getParentDirectoriesUrl(dir));
            }
        }

        if (path.length > 2 && this.directoryTree.directories) {
            const directoriesValues = Object.values(this.directoryTree.directories);
            if (this.directoryId === undefined) {
                this.selectedDirectory = directoriesValues.findIndex((val) => {
                    return val === path[2];
                });
            } else {
                const index = path.findIndex((id) => {
                    return id === this.directoryId;
                });
                this.selectedDirectory = directoriesValues.findIndex((val) => {
                    return val === path[index + 1];
                });
            }
        }
    }

    selectDirectory(index: number, id: string, e: Event) {
        e.preventDefault();
        if (this.selectedDirectory === index) {
            this.selectedDirectory = undefined;
            this.updateLocation(this.directoryTree.id);
            this.lawyerTreeService.setDocumentsListDirectory(this.directoryTree.id);
        } else {
            this.selectedDirectory = index;
            this.updateLocation(id);
            this.lawyerTreeService.setDocumentsListDirectory(id);
        }
    }

    async updateLocation(id: string) {
        try {
            const url: string = await this.getParentDirectoriesUrl(id);
            this.location.go(url);
        } catch (e) {
            console.error(e);
        }
    }

    async getParentDirectoriesUrl(id: string): Promise<string> {
        const firstDirectory = await this.listDocumentsService.getDirectory(id);

        const listOfDirectories: DirectoryInterface[] = [firstDirectory];
        while (listOfDirectories[listOfDirectories.length - 1].parent_id) {
            try {
                const parentDirectory = await this.listDocumentsService
                    .getDirectory(listOfDirectories[listOfDirectories.length - 1].parent_id);
                if (parentDirectory.id) {
                    listOfDirectories.push(parentDirectory);
                }
            } catch (e) {
                console.error(e);
            }
        }
        let url = 'lawyer-tree';
        for (let i = listOfDirectories.length - 2; i >= 0; i--) {
            url = url + '/' + listOfDirectories[i].id;
        }
        return url;
    }
}
