import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {UserService} from 'milcontratos';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;

  userName: string = '';
  openMenuMobile: boolean = false;
  showSubmenu: boolean = false;

  constructor(private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this.userSubscription = this.userService.getCurrentUserObs().subscribe((res) => {
      if (res) {
        if (res.displayName === null) {
          this.userName = 'Username';
        } else {
          this.userName = res.displayName;
        }
      } else {
        this.userName = '';
      }
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  async signOut() {
    await this.userService.signOut();
    this.redirectToFeed();
  }

  redirectToFeed() {
    this.router.navigate(['/home']);
  }

  /**
   * Hides the menu and resets the submenu folded state
   */
  onHideMenu() {
    this.openMenuMobile = false;
    this.showSubmenu = false;
  }

  onToggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  forceRefresh() {
  }
}
