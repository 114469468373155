<div class="mil-modal">
  <div class="tabs">
    <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Els meus documents</div>
    <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Document</div>
  </div>

  <div class="modal-box-split">
    <div class="modal-left" [class.tab-active]="tabs === 0">
      <div class="modal-left-buttons-box" *ngIf="showDocMenu">
        <div>
          <h2 class="title">Els meus documents</h2>
        </div>
        <div class="my-doc-buttons-box">
          <button type="button" class="waves-effect waves-light"
                  [ngClass]="{'btn-outline-selected': showTypePage === 'template', 'btn-outline': showTypePage !== 'template'}"
                  (click)="showDocPage('template')">Plantilles Descarregades</button>
          <button type="button" class="waves-effect waves-light"
                  [ngClass]="{'btn-outline-selected': showTypePage === 'draft', 'btn-outline': showTypePage !== 'draft'}"
                  (click)="showDocPage('draft')">Documents Començats</button>
          <!--<button type="button" class="waves-effect waves-light"
                  [ngClass]="{'btn-outline-selected': showTypePage === 'reviewing', 'btn-outline': showTypePage !== 'reviewing'}"
                  (click)="showDocPage('reviewing')">Documents en Revisió</button>-->
          <button type="button" class="waves-effect waves-light"
                  [ngClass]="{'btn-outline-selected': showTypePage === 'completed', 'btn-outline': showTypePage !== 'completed'}"
                  (click)="showDocPage('completed')">Documents Finalitzats</button>
            <button type="button" class="waves-effect waves-light"
                    [ngClass]="{'btn-outline-selected': showTypePage === 'billing', 'btn-outline': showTypePage !== 'billing'}"
                    (click)="navigateToBilling()">Veure Facturació</button>
        </div>
      </div>
    </div>
    <div class="modal-right" [class.tab-active]="tabs === 1">
      <app-documents-completed *ngIf="showTypePage === 'completed'"></app-documents-completed>
      <app-documents-draft *ngIf="showTypePage === 'draft'" (selectedDocumentToAddSigners)="documentToAddSigners($event)"></app-documents-draft>
      <app-documents-reviewing *ngIf="showTypePage === 'reviewing'" (selectedDocumentToAddSigners)="documentToAddSigners($event)"></app-documents-reviewing>
      <app-documents-template *ngIf="showTypePage === 'template'"></app-documents-template>
    </div>
  </div>
</div>
