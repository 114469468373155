<div class="mil-modal">
  <div class="modal-box-vertical-split">
    <div class="modal-top">
      <div *ngIf="loading" class="loading">
        <h2>
          Carregant...
        </h2>
      </div>
      <div *ngIf="!loading" class="modal-left">
        <perfect-scrollbar>
          <div class="back-button" (click)="goBack()">
            <i class="material-icons notranslate prefix">chevron_left</i>
            Endarrere
          </div>
          <h1 class="doc-title">{{document.name}}</h1>
          <div *ngIf="document">
            <div class="doc-price">
                {{price | number : '1.2-2'}}€
              <span>
                ({{ivaPrice | number : '1.2-2'}}€ amb
                <abbr title="Impuesto sobre el Valor Añadido">IVA</abbr>)
              </span>
            </div>
          </div>
          <h5 class="doc-includes">¿Què inclou la teva compra?</h5>
            <div class="doc-included-container">
                <div>
                    <div class="doc-included-item">
                        <i class="material-icons notranslate prefix doc-included-icon">check</i>
                        15% de descompte respecte el preu de venda habitual
                    </div>
                  <div class="doc-included-item">
                    <i class="material-icons notranslate prefix doc-included-icon">check</i>
                    Document legal
                  </div>
                  <div class="doc-included-item">
                    <i class="material-icons notranslate prefix doc-included-icon">check</i>
                    Signatura de diversos usuaris
                  </div>
                </div>
            </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="modal-bottom">
      <div class="pay-text">
        {{loadingPayButtons ? 'Carregant...' : 'Selecciona un mètode de pagament per a comprar aquest document'}}
      </div>
      <div class="pay-buttons-container"
           [class.hidden]="loadingPayButtons">
        <div #paypalButtonContainer class="paypal-button" id="paypal-button-container"></div>
          <button type="button" class="waves-effect waves-light btn pay-button card-pay-button" id="cardPayment">
              Targeta
          </button>
      </div>
    </div>
  </div>
</div>

<div id="errorModal" class="modal" materialize="Modal" [materializeParams]="[{dismissible: false}]"
     [materializeActions]="modalActions">
  <div class="modal-content">
    <h4>Error al comprar el document</h4>
    <p *ngIf="isDocumentOwned">No s'ha pogut comprar el document perquè ja el tens comprat.</p>
  </div>
  <div class="modal-footer">
    <a class="modal-action modal-close waves-effect btn-flat">Tanca</a>
  </div>
</div>

<app-card-payment [payer]="payer" (paymentComplete)="completePaymentCard($event)"></app-card-payment>
