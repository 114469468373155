<div class="mil-modal">
  <div class="tabs">
    <div class="tab" [class.active]="tabs === 0" (click)="tabs = 0">Signar document</div>
    <div class="tab" [class.active]="tabs === 1" (click)="tabs = 1">Veure document</div>
  </div>

  <div class="modal-box-split" *ngIf="!notValidSigner">
    <div class="modal-left" [class.tab-active]="tabs === 0">

      <div class="sign-container">
        <div class="sign-header" *ngIf="step <= 2 && !isUserAnonymous || step === 2">
          <div class="back" (click)="prevStep()">
            <i class="material-icons keyboard-arrow-left">keyboard_arrow_left</i>
            Enrrere
          </div>
        </div>

        <div class="sign-body" *ngIf="step <= 2">
          <div class="title">Signa el document</div>
          <div class="steps">Pas
            <span class="step-num">{{step}}</span>
            <span class="between-numbers">de</span>
            <span class="step-num">2</span></div>

          <div class="detail-container" *ngIf="step == 1">
            <div class="detail-title">Només manca la teva signatura</div>
            <div class="detail-text">Per a signar el document escriu el teu número de mòbil i rebràs un codi SMS per a verificar la teva firma.</div>

            <div class="detail-footer">
              <div class="label">Telèfon mòbil</div>
              <div class="input-phone input-custom">
                <span class="sufix">+34</span>
                <input type="text" [(ngModel)]="phone">
                <button (click)="sendPhone()" [disabled]="blockButton">{{ blockButton ? 'Enviant' : 'Enviar'}}</button>
              </div>
            </div>
          </div>

          <div class="detail-container" *ngIf="step == 2">
            <div class="detail-title">Introdueïx el codi SMS</div>
            <div class="detail-text">Introdueïx el codi de verificació que acabes de rebre per SMS. ¿No has rebut cap SMS? Fes clic <span class="styleAsTagA" (click)="sendPhone(true)">aquí</span> i te n'enviarem un altre. </div>
            <div class="detail-footer">
              <div class="label">Codi SMS</div>
              <div class="input-phone input-custom">
                <input type="text" class="uppercase" [(ngModel)]="smsCode">
                <button (click)="sendCompletionCode()" [disabled]="blockButton">
                  {{ blockButton ? 'Enviant' : 'Enviar'}}
                </button>
              </div>
            </div>
          </div>

        </div>

        <div class="sign-complete-container" *ngIf="step > 2">
          <div class="detail-container">
            <i class="material-icons check">check</i>
            <div class="title">Enhorabona, ja has signat el document</div>
            <div class="text">Pots revisar els teus documents signats a la teva àrea personal.
              En cas de no tenir un compte registrat, rebràs un e-mail quan tots hagin firmat el document.</div>
            <div class="download-buttons-box">
              <button class="btn-white" (click)="viewCompleteDocs()" >
                <i class="material-icons material-alternative-color">remove_red_eye</i>Veure els meus documents finalitzats
              </button>
              <button class="btn-white" (click)="downloadDocPdf()" >
                <i class="material-icons material-alternative-color">file_download</i>{{PDFStatus}}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div >
    <div class="modal-right" [class.tab-active]="tabs === 1">
      <div class="modal-right-inner-box">
        <div class="loading" *ngIf="loading">
          <div class="preloader-wrapper big active">
            <div class="spinner-layer spinner-red-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
            </div>
          </div>
        </div>
        <mc-doc-preview *ngIf="document && !isResponsive && !filledDocument?.annexes.length && !filledDocument?.overwriteDocument"
                        #preview
                        [document]="document"
                        [downloadable]="false"
                        [loadAnimation]="feedbackDownload"
                        [signId]="signId"
                        [hash]="signHash"
                        (download)="downloadDoc()"
                        (languageChange)="onChangeDocumentLanguage($event)"
                        (loaded)="updateWholeDocument()"></mc-doc-preview>
        <div *ngIf="(isResponsive || filledDocument?.annexes.length || filledDocument?.overwriteDocument) && pdfUrl"
             class="preview-box-external">
          <pdf-viewer [src]="pdfUrl" [c-maps-url]="'assets/cmaps/'"
                      [render-text]="true"
                      [original-size]="false"
                      [autoresize]="true"
                      style="display: block;">
          </pdf-viewer>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-box-split" *ngIf="notValidSigner">
    <div class="modal-left">

      <div class="sign-container">
        <div class="sign-header" *ngIf="step <= 2 && !isUserAnonymous || step === 2">
          <div class="back" (click)="prevStep()">
            <i class="material-icons keyboard-arrow-left">keyboard_arrow_left</i>
            Enrrere
          </div>
        </div>

        <div class="sign-body" >
          <div class="title">Signa el document</div>
          <div class="detail-container" >
            <div class="detail-title">No es troba cap document per signar</div>
            <div class="detail-text">Assegura't de que el propietari del document t'hagi enviat la versió més recent del document per signar</div>
          </div>
        </div>
      </div>
    </div >
  </div>
</div>
