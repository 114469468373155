<nav class="navbar-box">
  <div class="nav-bar">
    <div class="left-bar" (click)="redirectToFeed()"></div>
    <div class="left-bar-mobile">
      <i class="material-icons closed-menu-icon" *ngIf="!openMenuMobile" (click)="openMenuMobile = !openMenuMobile">menu</i>
      <div class="left-bar-mobile-title" *ngIf="!openMenuMobile" (click)="redirectToFeed()">PIMEC LegalTech</div>
    </div>

    <div class="right-bar">
      <div>
        <a [routerLink]="['search-document']" (click)="forceRefresh()" routerLinkActive="active">Cercador de documents</a>
      </div>
      <div>
        <a [routerLink]="['my-documents']" routerLinkActive="active">Els meus documents</a>
      </div>
      <div>
        <a [routerLink]="['support']" routerLinkActive="active">Ajuda</a>
      </div>
        <div *ngIf="userName === ''">
            <a class="enter-btn" [routerLink]="['sign-in']">Entrar</a>
        </div>
      <div *ngIf="userName !== ''">
        <a (click)="signOut()">Tanca la sessió</a>
      </div>
    </div>

    <div class="right-bar-mobile" [class.open]="openMenuMobile">
      <div class="left-bar-mobile">
        <div class="title-menu">
          <i class="material-icons opened-menu-icon" *ngIf="openMenuMobile" (click)="onHideMenu()">close</i>
          <div>PIMEC LegalTech</div>
        </div>
      </div>
      <ul class="menu-mobile">
          <li *ngIf="userName === ''" class="item">
              <a class="enter-btn" (click)="openMenuMobile = false;" [routerLink]="['sign-in']">Entrar</a>
          </li>
        <li *ngIf="userName !== ''" class="item" routerLinkActive="active">
          <a [routerLink]="['search-document']" (click)="onHideMenu()">Cercador de documents</a>
        </li>
        <li *ngIf="userName !== ''" class="item" routerLinkActive="active">
          <a [routerLink]="['my-documents']" (click)="onHideMenu()">Els meus documents</a>
        </li>
        <li *ngIf="userName !== ''" class="item" routerLinkActive="active">
          <a [routerLink]="['support']" (click)="onHideMenu()">Ajuda</a>
        </li>
        <li class="item" *ngIf="userName !== ''" (click)="signOut()">
          <a class="log-out-icon-container" (click)="onHideMenu()">
            <i class="material-icons log-out-icon">exit_to_app</i> Tanca la sessió
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
