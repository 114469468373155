import { Component, OnInit } from '@angular/core';

import {ChatStateService} from '../shared/services/chat/chat-state.service';


@Component({
  selector: 'app-footbar',
  templateUrl: './footbar.component.html',
  styleUrls: ['./footbar.component.scss']
})
export class FootbarComponent implements OnInit {

  constructor(private chatState: ChatStateService) { }

  ngOnInit() {}

  displayChat() {
    this.chatState.changeState(true);
  }
}
