<div class="search-box-right">
  <i class="material-icons prefix">search</i>
  <input type="text" class="input-text words-search"
         placeholder="Cercador legal"
         [(ngModel)]="searchText"
         (keyup)="searchDoc($event.target.value)"/>
</div>

<div class="selected-answers-box" *ngIf="questions">
  <div class="selected-answer" *ngFor="let question of questions; let last = last; let i = index;">
    <div *ngFor="let response of question.responses | keys">
      <div *ngIf="questions[i + 1] && questions[i + 1].id === response.value">
        <span class="btn-outline-selected" (click)="optionClicked(i)">
          {{response.key}} &nbsp;X
        </span>
        <span class="selected-answer-arrow" *ngIf="(questions.length - 2) !== i && !last">></span>
      </div>
    </div>
  </div>
</div>

<perfect-scrollbar *ngIf="!loadingDisplay && documents && documents.length > 0" class="docs-list-box">
    <div class="doc-box" *ngFor="let doc of documents">
        <div class="doc-box-left">
            <a [routerLink]="['/legal-document', doc.textId]" class="doc-title" (click)="documentSelected(doc.textId, $event)">{{doc.name}}</a>
            <p *ngIf="languages[doc.documentLanguage]" class="document-languages">
                {{(doc.availableLanguages | keys).length > 1 ? 'Idiomes:' : 'Idioma:'}}
                <a [routerLink]="['/legal-document', doc.textId, doc.documentLanguage]"
                   class="{{languages[doc.documentLanguage] ? 'flag-icon flag-icon-' + languages[doc.documentLanguage].flag : ''}}">
                </a>
                <ng-container *ngFor="let language of doc.availableLanguages | keyvalue">
                    <a *ngIf="language.key !== doc.documentLanguage"
                       [routerLink]="['/legal-document', doc.textId, language.key]"
                       class="{{languages[language.key] ? 'flag-icon flag-icon-' + languages[language.key].flag : ''}}">
                    </a>
                </ng-container>
            </p>
            <a [routerLink]="['/legal-document', doc.textId]" class="doc-type" (click)="documentSelected(doc.textId, $event)">{{doc.short_description}}</a>
        </div>
    </div>
</perfect-scrollbar>

<div *ngIf="!loadingDisplay && (!documents || documents.length === 0)" class="docs-list-box-not-found">
  <div class="not-found-message">
    Ens sap greu, no hem trobat cap document per a tu. Si ho desitges pots utilitzar el nostre
    <span class="text-display-chat" (click)="displayChat()">xat</span>
    o enviar-nos els teus dubtes mitjançant el
    <span class="text-display-chat"><a [routerLink]="['/support']">formulari d'ajuda</a></span>
  </div>
  <button type="button" class="waves-effect waves-light btn" (click)="resetSearch()">Torna a cercar</button>
</div>
<div *ngIf="loadingDisplay" class="docs-list-box-not-found">
  <div class="preloader-wrapper big active">
    <div class="spinner-layer spinner-red-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div><div class="gap-patch">
      <div class="circle"></div>
    </div><div class="circle-clipper right">
      <div class="circle"></div>
    </div>
    </div>
  </div>
</div>
